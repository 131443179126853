import {useState} from 'react'
import {useModalEditAsset} from '../../../../contexts/edit-asset/use-modal-edit-asset'
import {DataInput} from '../data-cell/data-input'

export function HostnameInput(): JSX.Element {
    const {currentAlias, setNewAlias} = useModalEditAsset()
    const [displayedValue, setDisplayedValue] = useState(currentAlias)

    const changed = currentAlias ? displayedValue !== currentAlias : displayedValue !== ''

    const blocked = !displayedValue || displayedValue.length === 0

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setDisplayedValue(e.target.value)
        setNewAlias(e.target.value)
    }

    return (
        <DataInput
            label="Asset alias"
            initialValue={displayedValue ?? ''}
            onChangeHandler={onChangeHandler}
            changed={changed}
            blocked={blocked}
        />
    )
}
