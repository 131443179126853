import {useEffect, useRef, useState} from 'react'
import * as Style from './create-asset-option-modal.styled'
import CHECKMARK_GREEN from '../../../@assets/icons/checkmark-green.svg'
import {useAssetOptions} from '../contexts/hooks/use-asset-options'
import LoadingState from '../../../values/loading-state-enum'
import {FormSubmitError} from './asset-creation-form'
import {CloseButton} from './close-button'
import {Spinner} from '../../../components/loading/spinner'
import {Colors} from '../../../theme/colours'
import {AssetOptionKey} from '../contexts/location-inventory-page-context'
import {FormError} from './form-error'
import {validateCreateAssetOption} from '../validation/ot-validation'

interface CreateAssetOptionModalProps {
    assetOptionKey: AssetOptionKey | null
    isOpen: boolean
    onCloseHandler: () => void
}

export function CreateAssetOptionModal({
    isOpen,
    onCloseHandler,
    assetOptionKey,
}: CreateAssetOptionModalProps): JSX.Element | null {
    let fieldName: string
    let inputLabel: string
    // null condition need to be explicitly handled
    // as assetOptionKey is null for starting state
    switch (assetOptionKey) {
        case null:
            return null
        case 'zones':
            inputLabel = fieldName = 'zone'
            break
        case 'systems':
            inputLabel = fieldName = 'system'
            break
        case 'assetTypes':
            fieldName = 'type'
            inputLabel = 'model'
            break
        case 'softwares':
            inputLabel = fieldName = 'software'
            break
        default:
            throw Error('fieldName is not specified yet!')
    }

    const placeholder = `Enter new ${fieldName} name`
    const {
        createAssetOption,
        assetFormOptions: {
            state: {formSubmitError, formSubmitLoadingState, formFieldError},
        },
        resetCreateAssetOptionForm,
        setCreateAssetOptionFormErrors,
    } = useAssetOptions()
    const modalRef = useRef<HTMLDialogElement | null>(null)
    const [name, setName] = useState('')
    const onClose = () => {
        resetCreateAssetOptionForm()
        setName('')
        onCloseHandler()
    }
    useEffect(() => {
        const modalElement = modalRef.current
        if (modalElement) {
            if (isOpen) {
                modalElement.showModal()
            } else {
                modalElement.close()
            }
        }
    }, [isOpen])
    const handleSubmitModal = () => {
        const {success, formFieldError} = validateCreateAssetOption(name)
        if (!success) {
            setCreateAssetOptionFormErrors(formFieldError)
            return
        }
        createAssetOption({name}, assetOptionKey)
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === 'Escape') {
            onClose()
        }
    }

    const isNotEditable =
        formSubmitLoadingState === LoadingState.RequestingData ||
        formSubmitLoadingState === LoadingState.Loaded

    let bottomDiv: JSX.Element
    if (formSubmitLoadingState === LoadingState.Loaded) {
        bottomDiv = (
            <Style.SuccessMessage>
                <img src={CHECKMARK_GREEN} />
                &nbsp;"{name}" added!
            </Style.SuccessMessage>
        )
    } else {
        bottomDiv = (
            <Style.Button color={Colors.blue2} onClick={handleSubmitModal} primary={false}>
                {formSubmitLoadingState === LoadingState.RequestingData && (
                    <Spinner color={Colors.grey4} />
                )}
                Add {fieldName}
            </Style.Button>
        )
    }

    return (
        <Style.Dialog ref={modalRef} onKeyDown={handleKeyDown}>
            <CloseButton onClick={onClose} />
            <Style.FormColumn>
                <Style.Header>
                    <h4>Add new {fieldName}</h4>
                </Style.Header>

                <div>
                    <Style.FormLabel>Name of {inputLabel}</Style.FormLabel>
                    <Style.ModalTextInput
                        value={name}
                        placeholder={placeholder}
                        onChange={(event) => setName(event.target.value)}
                        disabled={isNotEditable}
                        form="add-asset-option-form"
                        loadingState={formSubmitLoadingState}
                    />
                    <FormError error={formFieldError.name} />
                </div>

                {bottomDiv}
                <FormSubmitError error={formSubmitError} />
            </Style.FormColumn>
        </Style.Dialog>
    )
}
