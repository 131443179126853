import {FormEvent, useContext} from 'react'
import Overlay from '../../../modals/shared-components/overlay'
import {LocationInventoryPageContext} from '../contexts/location-inventory-page-context'
import {Button} from '../../../components/base/button'
import CLOSE_ICON from '../../../@assets/icons/close-panel.svg'
import {
    TitleText,
    Content,
    TitleRow,
    CloseRow,
    InstructionTitleText,
    ModalDialog,
    SubtitleText,
    InstructionBodyText,
    QuestionRow,
    QuestionText,
    QuestionFieldset,
    Logo,
    ActionsWrapper,
    TopSection,
    MiddleSection,
    BottomSection,
    QuestionOptionLabel,
    QuestionsWrapper,
    SubmissionError,
} from './system-assessment-modal.styled'
import {useSystemAssessmentModal} from '../contexts/hooks/use-system-assessment-modal'
import {OTAssessmentReq} from '../contexts/types/ot-types'
import Owl from '../../../@assets/images/owl-black.png'
import LoadingState from '../../../values/loading-state-enum'

export function SystemAssessmentModal(): JSX.Element | null {
    const {systemAssessmentModal} = useContext(LocationInventoryPageContext)

    const {
        state: {systemID, submissionState, error},
    } = systemAssessmentModal
    const {submitAssessment, closeModal} = useSystemAssessmentModal()

    const questions = {
        q1: 'Is the system stand-alone and <em>not</em> connected to other systems?',
        q2: 'Is the system connected externally, either directly or via other systems?',
        q3: 'Is the system easy to physically access?',
        q4: 'Does the system combine multiple ship functions into a single control system?',
        q5: 'Does the system allow remote access?',
        q6: 'Are there any known cybersecurity vulnerabilities in this system?',
        q7: 'Does the system have effective, built-in risk mitigation measures such as encryption?',
        q8: 'Does the system require regular software updates?',
        q9: 'Is the system categorised as either a Category I (Critical) or II (Important) System?',
        q10: 'Does operating the system involve connecting removable devices, for example to obtain diagnostic information?',
    }

    const submitForm = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const mapper = (value: string) => (value === '1' ? true : false)

        const target = e.target as typeof e.target & {
            q1: {value: string}
            q2: {value: string}
            q3: {value: string}
            q4: {value: string}
            q5: {value: string}
            q6: {value: string}
            q7: {value: string}
            q8: {value: string}
            q9: {value: string}
            q10: {value: string}
        }

        const req: OTAssessmentReq = {
            q1: mapper(target.q1.value),
            q2: mapper(target.q2.value),
            q3: mapper(target.q3.value),
            q4: mapper(target.q4.value),
            q5: mapper(target.q5.value),
            q6: mapper(target.q6.value),
            q7: mapper(target.q7.value),
            q8: mapper(target.q8.value),
            q9: mapper(target.q9.value),
            q10: mapper(target.q10.value),
        }

        if (!systemID) {
            throw Error('systemID cannot be null')
        }

        submitAssessment(systemID, req)
    }

    const questionsComponent = []

    for (const [k, v] of Object.entries(questions)) {
        questionsComponent.push(
            <QuestionRow key={k}>
                <QuestionText dangerouslySetInnerHTML={{__html: v}} />
                <QuestionFieldset>
                    <input name={`${k}`} type="radio" value="1" required id={`${k}-yes`} />
                    <input name={`${k}`} type="radio" value="0" required id={`${k}-no`} />
                    <QuestionOptionLabel htmlFor={`${k}-yes`}>Yes</QuestionOptionLabel>
                    <QuestionOptionLabel htmlFor={`${k}-no`}>No</QuestionOptionLabel>
                </QuestionFieldset>
            </QuestionRow>,
        )
    }

    return (
        <Overlay closing={false}>
            <ModalDialog>
                <Content>
                    <TopSection>
                        <CloseRow>
                            <Button onClick={closeModal} variant="icon" size="xs">
                                <img src={CLOSE_ICON} alt="close" />
                            </Button>
                        </CloseRow>
                        <Logo>
                            <img src={Owl} />
                        </Logo>
                        <TitleRow>
                            <TitleText>OT System vulnerability assessment</TitleText>
                        </TitleRow>
                        <TitleRow>
                            <SubtitleText>
                                Please answer the below questions to assess your OT system's
                                vulnerability
                            </SubtitleText>
                        </TitleRow>
                        <InstructionTitleText>
                            Complete the system vulnerability assessment
                        </InstructionTitleText>
                        <InstructionBodyText>
                            This assessment will help ensure the reliability, safety and security of
                            your OT system
                        </InstructionBodyText>
                    </TopSection>
                    <form onSubmit={submitForm}>
                        <MiddleSection>
                            <QuestionsWrapper>{questionsComponent}</QuestionsWrapper>
                        </MiddleSection>
                        <BottomSection>
                            <ActionsWrapper>
                                <button style={{visibility: 'hidden'}}></button>
                                {submissionState === LoadingState.Errored && (
                                    <SubmissionError>{error}</SubmissionError>
                                )}
                                <Button type="submit" size="lg">
                                    Save
                                </Button>
                            </ActionsWrapper>
                        </BottomSection>
                    </form>
                </Content>
            </ModalDialog>
        </Overlay>
    )
}
