import {LoadingSpinner} from '../../../../components/loading/loading'
import LoadingState from '../../../../values/loading-state-enum'
import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import {IsolationOperation} from '../../context/types/isolated-assets-type'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {showTrustToggle, toggleOn} from '../helpers/data-helpers'
import {SwitchToggle} from '../switch-toggle/switch-toggle'
import {TableDataCell} from './_styled/table-data-cell.styled'
import {LoadingSpinnerWrapper} from './_styled/trust-device-cell.styled'

interface TrustDeviceCellProps {
    anomaly: FormattedAnomaly
    index: number
}

export function TrustDeviceCell({anomaly, index}: TrustDeviceCellProps): JSX.Element {
    const {actionTrustedDevice, loadingToggleState} = usePagedUnknownAssetsBeta()
    const gridRow = index + 1

    return (
        <TableDataCell
            gridRow={gridRow}
            gridColumn={9}
            id={`network-assets-trust-device_${anomaly.identity}`}
            centered
            notClickable
        >
            {loadingToggleState.id === anomaly.identity &&
            loadingToggleState.loading === LoadingState.RequestingData ? (
                <LoadingSpinnerWrapper>
                    <LoadingSpinner size={25} />
                </LoadingSpinnerWrapper>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        columnGap: '10px',
                    }}
                >
                    {showTrustToggle(anomaly.isolationStatus.isolationStatus) && (
                        <>
                            <SwitchToggle
                                onValue={toggleOn(anomaly.isolationStatus.isolationStatus)}
                                setToggleLeftValue={(e: React.MouseEvent<unknown>) => {
                                    e.stopPropagation()
                                    actionTrustedDevice(anomaly.identity, IsolationOperation.BLOCK)
                                }}
                                setToggleRightValue={(e: React.MouseEvent<unknown>) => {
                                    e.stopPropagation()
                                    actionTrustedDevice(anomaly.identity, IsolationOperation.TRUST)
                                }}
                                identifier={anomaly.identity}
                            />
                            Trust
                        </>
                    )}
                </div>
            )}
        </TableDataCell>
    )
}
