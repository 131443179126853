import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'

import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {FormattedMetricData, MetricTypeResponseMap} from './state'
import {MetricsSchema} from '../../../pages/policy-management/contexts/metrics-policy/type/metrics-policy-schema'
import {MetricType} from '../../../pages/metrics-beta/contexts/types/metrics-response'
import {StringUtils} from '../../../utils/Utils'

const TYPES_ENDPOINT = 'metrics/api/v1/metrics/types'
const METRICS_SCHEMA_ENDPOINT = 'metrics/api/v1/metrics/schema'

const requestMetricTypes = (): Actions.RequestMetricTypesAction => ({
    type: ActionType.REQUEST_METRIC_TYPES,
})
const setMetricTypes = (
    dataMetricResponseMap: MetricTypeResponseMap,
    formattedMetricData: FormattedMetricData[],
): Actions.ReceivedMetricTypesAction => ({
    type: ActionType.RECEIVED_METRIC_TYPES,
    payload: {dataMetricResponseMap, formattedMetricData},
})

export const fetchMetricTypes = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return async (dispatch) => {
        dispatch(requestMetricTypes())

        try {
            const [schemasResponse, metricTypesResponse] = await Promise.all([
                REST.get(METRICS_SCHEMA_ENDPOINT),
                REST.get(TYPES_ENDPOINT),
            ])

            const receivedSchemas = schemasResponse.data as MetricsSchema[]
            const receivedMetricTypes = new Map(
                Object.entries(metricTypesResponse.data),
            ) as MetricTypeResponseMap

            const formattedMetricData: FormattedMetricData[] = []

            // Iterate through metric types and match them with the corresponding schema
            for (const [metricType, metricTypeArray] of receivedMetricTypes) {
                const schema = receivedSchemas.filter(
                    (schema) => (schema.metricGroup as unknown as MetricType) === metricType,
                )
                metricTypeArray.forEach((metricType) => {
                    const matchedSchema = schema.find(
                        (metricFromSchema) => metricType === metricFromSchema.name,
                    )

                    if (matchedSchema) {
                        formattedMetricData.push({
                            name: matchedSchema.name,
                            displayName: matchedSchema.displayName,
                            metricGroup: matchedSchema.metricGroup,
                        })
                    } else {
                        formattedMetricData.push({
                            name: metricType,
                            displayName: StringUtils.capitaliseString(
                                metricType.replace(/([A-Z])/g, ' $1'),
                            ),
                            metricGroup: '',
                        })
                    }
                })
            }

            dispatch(setMetricTypes(receivedMetricTypes, formattedMetricData))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            dispatch(
                setMetricTypes(new Map<MetricType, MetricType[]>(), [] as FormattedMetricData[]),
            )
        }
    }
}
