import createCachedSelector from 're-reselect'
import {locationMapSelector} from '../../../store/state/locations/selectors'
import {Location, LocationIdType} from '../../../store/state/locations/state'
import {nodesForLocationsSelector} from '../../../store/state/nodes/selectors'
import AppState from '../../../store/types/app-state'
import {VesselDetailsOutputModel} from './vessel-details.model'
import {vesselTagsForLocationsSelector} from '../../../store/state/vessel-tags/selectors'
import {VesselTags} from '../../../store/state/vessel-tags/state'
import {formatVesseltagsForUI, VesselTagsAPI} from '../../../store/state/vessel-tags/helper'
import {IsolationMode, LocationInIsolationMode} from '../contexts/type/location-in-isolation.mode'
import {
    AllPolicyData,
    PolicyType,
} from '../../policy-management/contexts/policy-management/type/policy-management-state'

function formatNumberOfAssets(numberOfAssets: number): string {
    return numberOfAssets === 1 ? '1 asset' : `${numberOfAssets} assets`
}

function sortedTagsForLocation(vesselTags: VesselTagsAPI[] | undefined): VesselTags[] {
    if (!vesselTags) {
        return []
    }
    return [...vesselTags]
        ?.map((element) => formatVesseltagsForUI(element))
        .sort((a: VesselTags, b: VesselTags) => a.name?.localeCompare(b.name))
}

export const vesselDetailsCachedReselector = createCachedSelector(
    [
        locationMapSelector,
        nodesForLocationsSelector,
        vesselTagsForLocationsSelector,
        (
            _state: AppState,
            locationId: LocationIdType | null,
            locationsInIsolationMode: LocationInIsolationMode[],
            allPolicyData: AllPolicyData[],
        ) => ({
            locationId,
            locationsInIsolationMode,
            allPolicyData,
        }),
    ],
    (locations, nodesForLocations, vesselTagsForLocations, params): VesselDetailsOutputModel => {
        const {locationId, locationsInIsolationMode, allPolicyData} = params
        if (locationId === null) {
            throw Error('Could not find location')
        }
        const location: Location | undefined = locations.get(locationId)
        if (!location) {
            throw Error('Could not find location')
        }

        const incidentPolicy = allPolicyData
            ?.filter((policy) => policy.policyType === PolicyType.INCIDENT)
            .find((policy) => policy.locations?.includes(location.location))

        const metricsPolicy = allPolicyData
            ?.filter((policy) => policy.policyType === PolicyType.METRICS)
            .find((policy) => policy.locations?.includes(location.location))

        const enforcementPolicy = allPolicyData
            ?.filter((policy) => policy.policyType === PolicyType.ENFORCEMENT)
            .find((policy) => policy.locations?.includes(location.location))

        return {
            id: locationId,
            name: location.description,
            shipType: location.type ?? 'Unknown',
            imoNumber: location?.customerLocationCode ?? 'Unknown',
            numberOfAssets: formatNumberOfAssets(
                nodesForLocations.get(location.location)?.length ?? 0,
            ),
            vesselTags: sortedTagsForLocation(vesselTagsForLocations.get(location.location)),
            metadata: {
                networkConfiguration: {
                    BusinessNetworks: location.metaData?.network?.BusinessNetworks,
                    CrewNetworks: location.metaData?.network?.CrewNetworks,
                    OTNetworks: location.metaData?.network?.OTNetworks,
                    Gateway: {
                        IP: undefined,
                        MAC: undefined,
                    },
                },
                vesselDashboardAgentIds: location.metaData?.vesselDashboardAgentIds
                    ? location.metaData?.vesselDashboardAgentIds
                    : [],
                otSources: {
                    syslog: location.metaData?.otSources?.syslog,
                    nmea: location.metaData?.otSources?.nmea,
                },
            },
            nodesForLocation:
                nodesForLocations.get(locationId)?.filter((node) => node.agentId) ?? [],
            vesselEmail:
                location.emailAddress === null || location.emailAddress === 'null'
                    ? ''
                    : location.emailAddress ?? '',
            vesselDashboard: location.vesselDashboard || false,
            dashboardPC: nodesForLocations
                .get(locationId)
                ?.filter((node) => node.agentId)
                .find(
                    (node) =>
                        node.agentId === location.metaData?.vesselDashboardAgentIds?.toString(),
                )?.alias,
            isolationMode:
                locationsInIsolationMode?.find(
                    (locationMode) => locationMode.identity === location.location,
                )?.isolationMode ?? IsolationMode.IsolationModeUndefined,
            incidentPolicy: {
                id: incidentPolicy?.identity,
                name: incidentPolicy?.policyName ?? 'Default',
                locations: incidentPolicy?.locations,
            },
            metricsPolicy: {
                id: metricsPolicy?.identity,
                name: metricsPolicy?.policyName ?? 'Default',
                locations: metricsPolicy?.locations,
            },
            enforcementPolicy: {
                id: enforcementPolicy?.identity,
                name: enforcementPolicy?.policyName ?? 'Default',
                locations: enforcementPolicy?.locations,
            },
        }
    },
)((_state, locationId) => locationId)
