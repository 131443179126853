import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../theme/font-styles'
import {NetworkAssetState} from '../../context/types/isolated-assets-type'
import {CyberOwlTheme} from '../../../../theme/theme'
import {NodeValue, NodeValues} from '../../../../values/nodes/NodeData'

interface ContainerWrapperProps {
    bottomPosition: boolean
}

export const Container = styled.div<ContainerWrapperProps>`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    width: 320px;
    position: absolute;
    ${(props) => (props.bottomPosition ? 'bottom: 20px' : 'top: 20px')};
    left: 0;
    z-index: 100;
`

export const Header = styled.header`
    display: flex;
    justify-content: flex-start;
    min-height: 50px;
    border-radius: 6px;
`

interface TitlesProps {
    networkAssetState: NetworkAssetState
}
export const Titles = styled.div<TitlesProps>`
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    border-left: 1px solid white;
    padding: ${spacing(1)} ${spacing(3)};
    background-color: ${(props) => getBackgroundColorForState(props.networkAssetState)};
`
function getBackgroundColorForState(networkAssetState: NetworkAssetState): string {
    switch (networkAssetState) {
        case NetworkAssetState.TRUSTED:
            return '#2FDF2C'
        case NetworkAssetState.BLOCKED:
            return '#F34663'
        case NetworkAssetState.UNKNOWN:
        default:
            return '#F8B24B'
    }
}
export const Names = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`

export const AssetName = styled.h2`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const StatusValue = styled.span`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
    margin: 0 0 0 ${spacing(5)};
    padding: 0;
    text-transform: uppercase;
    text-align: center;
`

export const VesselName = styled.h3`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const Details = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
    column-gap: ${spacing(5)};
    row-gap: ${spacing(1)};
    margin: ${spacing(3)};
`

export const Label = styled.label`
    ${smallFont()}
    font-weight:${(props) => props.theme.font.weight.semibold};
    margin: 0;
    padding: 0;
`

export const Value = styled.span`
    ${smallFont()}
    font-weight:${(props) => props.theme.font.weight.bold};
    margin: 0;
    padding: 0;
`

export const Actions = styled.footer`
    border-top: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(1)} ${spacing(3)};
`

export const DetailText = styled.span`
    margin-left: ${spacing(1)};
`
interface CircleProps {
    value: NodeValue
}

export const ValueWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`
export const ValueCircle = styled.div<CircleProps>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => getNodeValueBackgroundColor(props.theme, props.value)};
`
function getNodeValueBackgroundColor(theme: CyberOwlTheme, value: NodeValues | undefined): string {
    switch (value) {
        case NodeValues.HIGH:
            return theme.threatScores.score.lowScoreHighValue.background
        case NodeValues.MEDIUM:
            return theme.threatScores.score.lowScoreMediumValue.background
        case NodeValues.LOW:
        default:
            return theme.threatScores.score.lowScoreLowValue.background
    }
}
