import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'

import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {User} from './state'

const USERS_URL = '/api/v1/users'

const setUsers = (users: User[]): Actions.SetUserArrayAction => ({
    type: ActionType.SET_USERS,
    payload: users,
})

const requestUsers = (): Actions.NoPayloadAction => ({
    type: ActionType.REQUEST_USERS,
})

export const fetchUsers = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestUsers())
        REST.get(USERS_URL)
            .then((response) => {
                dispatch(setUsers(response.data))
            })
            .catch(() => setUsers([] as User[]))
    }
}
