import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const Panel = styled.a`
    display: flex;
    justify-content: space-between;
    border: 2px solid #a7b0be;
    border-radius: 3px;
    padding: 8px ${spacing(3)};
    &:focus,
    &:active {
        border: 2px solid #1f88e5;
        outline: none;
    }
    &:link,
    &:visited {
        color: #000;
    }
`
export const Label = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
`
export const Placeholder = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
    color: #a7b0be;
`

export const Box = styled.div`
    position: relative;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    width: 180px;
`
export const DropdownContainer = styled.div`
    position: absolute;
    border: 1px solid #a7b0be;
    border-radius: 0px, 0px, 5px, 5px;
    background-color: #fff;
    width: 100%;
    padding: 9px 15px 15px 15px;
    z-index: 1;
`
export const OptionContainer = styled.div`
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
`

export const Option = styled.a`
    display: block;
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 4px;
    white-space: pre-wrap;
    &:hover,
    &:focus,
    &:active {
        background-color: #f1f6fb;
        outline: none;
    }
    &:link,
    &:visited {
        color: #000;
    }
`

export const SearchInput = styled.input`
    width: 100%;
    border: 2px solid #a7b0be;
    padding: 10px;
    border-radius: 3px;
    &:focus {
        outline: none;
        border: 2px solid #1f88e5;
    }
`

export const GroupName = styled.h5`
    margin: 0;
    padding-inline: 8px;
    padding-block: 5px;
    font-size: 14px;
`
