import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'

import {SelfMonitoringEngineEvent} from '../../../values/self-monitoring-event'

import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'

const EVENTS_URL =
    '/api/v1/selfMonitoringEvents/find?count=30&offset=0&orderAscending=false&orderBy=TIMESTAMP'

function requestSelfMonitoringEngineEvents(): Actions.RequestSelfMonitoringEngineEventsAction {
    return {type: ActionType.REQUEST_SELF_MONITORING_ENGINE_EVENTS}
}
function setSelfMonitoringEngineEvents(
    data: SelfMonitoringEngineEvent[],
): Actions.ReceivedSelfMonitoringEngineEventsAction {
    return {
        type: ActionType.RECEIVED_SELF_MONITORING_ENGINE_EVENTS,
        payload: data,
    }
}

export function fetchSelfMonitoringEngineEvents(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch) => {
        dispatch(requestSelfMonitoringEngineEvents())
        REST.get(EVENTS_URL)
            .then((response) => {
                dispatch(setSelfMonitoringEngineEvents(response.data.data))
            })
            .catch(() => setSelfMonitoringEngineEvents([] as SelfMonitoringEngineEvent[]))
    }
}
