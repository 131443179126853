import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {SelfMonitoringEngineRule} from '../../../values/self-monitoring-rule'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import AppState from '../../types/app-state'
const RULES_URL = '/api/v1/selfMonitoringRules'

export function fetchSelfMonitoringEngineRules(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestSelfMonitoringEngineRules())
        REST.get(RULES_URL)
            .then((response) => {
                dispatch(setSelfMonitoringEngineRules(response.data))
            })
            .catch(() => setSelfMonitoringEngineRules([] as SelfMonitoringEngineRule[]))
    }
}
function requestSelfMonitoringEngineRules(): Actions.RequestSelfMonitoringEngineRulesAction {
    return {
        type: ActionType.REQUEST_SELF_MONITORING_ENGINE_RULES,
    }
}
function setSelfMonitoringEngineRules(
    rules: SelfMonitoringEngineRule[],
): Actions.ReceivedSelfMonitoringEngineRulesAction {
    return {
        type: ActionType.RECEIVED_SELF_MONITORING_ENGINE_RULES,
        payload: rules,
    }
}
