import createCachedSelector from 're-reselect'
import AppState from '../../../../store/types/app-state'
import {AssetDisplayDetailsModel} from '../types/asset-display-details-model'
import {nodesSelector} from '../../../../store/state/nodes/selectors'
import {latestTimestampSelector} from '../../../../store/state/latest-event-timestamps/selectors'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {getNodeLatestEvent} from '../../../../values/nodes/NodeData'
import {getLocationDescription} from '../../../../store/state/locations/state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {getDisplayNameOfAsset} from '../helpers'

export const assetDisplayDetailsCachedReselector = createCachedSelector(
    [
        nodesSelector,
        latestTimestampSelector,
        locationMapSelector,
        (_state: AppState, assetId: GuidType) => assetId,
    ],
    (nodes, latestEventTimestamps, locationMap, assetId) => {
        const nodeData = nodes && nodes?.get(assetId)
        return {
            assetId: nodeData?.node,
            displayName: getDisplayNameOfAsset(nodeData),
            vesselName: nodeData
                ? getLocationDescription(locationMap, nodeData.location)
                : undefined,
            lastEvent: getNodeLatestEvent(assetId, latestEventTimestamps),
            hostname: nodeData?.hostName,
            ipAddress: nodeData?.ipAddress,
            macAddress: nodeData?.ipAddress,
            value: nodeData?.value,
            alias: nodeData?.alias,
        } as AssetDisplayDetailsModel
    },
)((_state, assetId) => assetId)
