import * as Styled from './components/edit-asset-modal.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import CLOSE_ICON from '../../../../@assets/icons/close-assign-to-icon.svg'

import {SubmitArea} from './components/submit-area/submit-area'
import {SelectBusinessValue} from './components/business-value/business-value'
import {HostnameInput} from './components/hostname/hostname'
import {SaveInProgress} from './components/data-area/save-in-progress'
import {ChangeSaved} from './components/data-area/change-saved'
import {ChangeNotSaved} from './components/data-area/change-not-saved'
import {useModalEditAsset} from '../../contexts/edit-asset/use-modal-edit-asset'
import {ProcessingStatus} from '../../../../values/processing-save-state-enum'
import {removeQueryParam} from '../../../../helpers/navigation'
import {Overlay} from '../../../shared-components/modal-overlay'
import {ModalEditAssetProvider} from '../../contexts/edit-asset/modal-edit-asset-provider'
import {useEffect} from 'react'
import LoadingState from '../../../../values/loading-state-enum'
import {DataLoading} from '../../../../components/data-loading/data-loading'

export function EditAssetModal(): JSX.Element {
    return (
        <Overlay>
            <ModalEditAssetProvider>
                <EditAssetContent />
            </ModalEditAssetProvider>
        </Overlay>
    )
}

function EditAssetContent(): JSX.Element {
    const {isProcessingSave, setAimeeAsset, loadingAimeeData} = useModalEditAsset()
    const {width} = useDimensions()

    useEffect(() => {
        setAimeeAsset()
    }, [])

    return (
        <Styled.Section onClick={(e) => e.stopPropagation()} width={width} id="edit-asset-section">
            <Styled.Header id="edit-asset-Header">
                <Styled.Title id="edit-asset-Title">Edit Asset</Styled.Title>
                <Styled.CloseImage
                    src={CLOSE_ICON}
                    onClick={() => {
                        removeQueryParam('editAsset')
                    }}
                    id="detail-panel-header_close-details-icon"
                />
            </Styled.Header>
            {isProcessingSave === ProcessingStatus.PROCESSING && <SaveInProgress />}
            {isProcessingSave === ProcessingStatus.PROCESSED && <ChangeSaved />}
            {isProcessingSave === ProcessingStatus.FAILED && <ChangeNotSaved />}
            {isProcessingSave === ProcessingStatus.NOT_PROCESSING && (
                <>
                    <Styled.Settings width={width} id="edit-asset-Settings">
                        {loadingAimeeData === LoadingState.Loaded ? (
                            <Styled.Filters width={width} id="edit-asset-Filters">
                                <SelectBusinessValue />
                                <HostnameInput />
                            </Styled.Filters>
                        ) : (
                            <DataLoading size={60} />
                        )}
                    </Styled.Settings>
                    <Styled.Actions id="edit-asset-Actions">
                        <SubmitArea />
                    </Styled.Actions>
                </>
            )}
        </Styled.Section>
    )
}
