import {Check, X} from 'react-feather'
import {DataCell} from './data-cell'
import {IconWrapper, Input, InputWrapper, WarningMessage} from './data-cell.styled'

interface DataInputProps {
    label: string
    initialValue: string
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    changed: boolean
    blocked?: boolean
}

export function DataInput({
    label,
    initialValue,
    onChangeHandler,
    changed,
    blocked,
}: DataInputProps): JSX.Element {
    return (
        <DataCell label={label} blocked={blocked}>
            <InputWrapper id={`edit-asset-InputWrapper_${label}`}>
                <Input
                    value={initialValue}
                    onChange={onChangeHandler}
                    id={`edit-asset-Input_${initialValue}`}
                />
                <IconWrapper id={`edit-asset-IconWrapper_${label}`}>
                    {changed && !blocked && <Check color={'green'} width={19} height={19} />}
                    {blocked && <X color={'#f34663'} width={19} height={19} />}
                </IconWrapper>
            </InputWrapper>
            {blocked && (
                <WarningMessage id={`edit-asset-WarningMessage_${label}`}>
                    The alias is required.
                </WarningMessage>
            )}
        </DataCell>
    )
}
