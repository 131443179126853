import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'
import {AssetStatus} from '../../../store/state/ot-assets/state'

interface Props {
    status: AssetStatus
}

const Colors: Record<AssetStatus, string> = {
    [AssetStatus.ACTIVE]: '#0E7A0E',
    [AssetStatus.DECOMMISSIONED]: '#D9D9D9',
    [AssetStatus.INACTIVE]: '#A60606',
}

const CircleIcon = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: ${spacing(2)};
    display: flex-item;
    background: ${(props) => props.color};
`
const Label = styled.span`
    font-size: 14px;
    line-height: 19.07px;
    text-transform: capitalize;
    display: flex-item;
`
const Container = styled.div`
    display: flex;
    align-items: center;
`

export function AssetLifeCycle({status}: Props): JSX.Element {
    return (
        <Container>
            <CircleIcon color={Colors[status] || Colors.inactive} />
            <Label>{status}</Label>
        </Container>
    )
}
