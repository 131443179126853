import {Dispatch, useContext} from 'react'
import {REST} from '../../../..'
import {warn} from '../../../../helpers/logging'
import {PagedUniqueFlowsContext} from './paged-unique-flows-context'
import * as ActionCreators from './state/action-creators'
import {AllActions} from './state/actions'
import {UsePagedUniqueFlowsResult} from './use-paged-unique-flows-output'

const UNIQUE_FLOWS_URL = '/api/v1/mona/unique-flow/find'

function getData(
    dispatch: Dispatch<AllActions>,
    assetId: string,
    offset: number,
    pageSize: number,
    fromDate: string | undefined,
    toDate: string | undefined,
): void {
    REST.post(UNIQUE_FLOWS_URL, {
        from: fromDate,
        to: toDate,
        pagination: {offset: offset, count: pageSize},
        knownSourceOrDestination: assetId,
    })
        .then((response) => {
            dispatch(
                ActionCreators.receivedRequestedPageData(
                    response.data.uniqueFlows,
                    response.data.totalNumberOfUniqueFlows ?? 0,
                ),
            )
        })
        .catch((error) => dispatch(ActionCreators.setError(error)))
}

export function usePagedUniqueFlows(): UsePagedUniqueFlowsResult {
    const {state, dispatch} = useContext(PagedUniqueFlowsContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedUniqueFlows must be used within a PagedUniqueFlowsContext')
    }

    function setPageSize(pageSize: number): void {
        if (!dispatch) {
            warn('dispatch is undefined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is undefined')
            return
        }

        dispatch(ActionCreators.changePageSize(pageSize))
        getData(dispatch, state.assetId, 0, pageSize, state.fromDate, state.toDate)
    }

    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is undefined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is undefined')
            return
        }

        dispatch(ActionCreators.requestInitialPageData())
        getData(dispatch, state.assetId, 0, state.pageSize, state.fromDate, state.toDate)
    }

    function selectPage(requestedPage: number | undefined): void {
        if (!dispatch) {
            warn('dispatch is undefined')
            return
        }

        if (requestedPage == undefined) {
            warn('requestedPage is undefined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is undefined')
            return
        }

        if (state.dataPages && state.dataPages.has(requestedPage)) {
            dispatch(ActionCreators.switchToCachedPageData(requestedPage))
            return
        }

        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))
        getData(dispatch, state.assetId, offset, state.pageSize, state.fromDate, state.toDate)
    }

    function clearDateFilter(): void {
        if (!dispatch) {
            warn('dispatch is undefined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is undefined')
            return
        }

        dispatch(ActionCreators.clearDateFilter())
        getData(
            dispatch,
            state.assetId,
            (state.selectedPage ?? 0) * state.pageSize,
            state.pageSize,
            undefined,
            undefined,
        )
    }

    function setDateFilter(fromDate: string | undefined, toDate: string | undefined): void {
        if (!dispatch) {
            warn('dispatch is undefined')
            return
        }

        if (!state.assetId) {
            warn('state.assetId is undefined')
            return
        }

        dispatch(ActionCreators.setDateFilter(fromDate, toDate))
        getData(dispatch, state.assetId, 0, state.pageSize, fromDate, toDate)
    }

    const hasData = state.totalNumberOfFlows != undefined
    const dataPage =
        hasData && state.selectedPage != undefined
            ? state.dataPages?.get(state.selectedPage) ?? undefined
            : undefined

    return {
        assetId: state.assetId,
        loading: state.loading,
        pageSize: state.pageSize,
        dataPage: dataPage,
        totalNumberOfFlows: state.totalNumberOfFlows,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        fromDate: state.fromDate,
        toDate: state.toDate,
        setPageSize,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        clearDateFilter,
        setDateFilter,
        //...useMemo(() => buildActionCreators(dispatch, state.assetId), [dispatch, state.assetId]),
    }
}
