import useTypedSelector from '../../../../hooks/use-typed-selector'
import {TableRow} from './table-row'
import {TableDataGridStyle} from './_styled/table-data-grid.styled'
import {NoDataTable} from './no-data-table/no-data-table'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {setFormattedAnomalies} from '../../context/helpers'
import {
    activeLocationSelector,
    locationMapSelector,
} from '../../../../store/state/locations/selectors'
import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {NetworkAssetDetailsModal} from './network-asset-details-modal/network-asset-details-modal'

export function UnknownAssetsTablePopulated(): JSX.Element {
    const activeLocation = useTypedSelector(activeLocationSelector)
    const locationMap = useTypedSelector(locationMapSelector)
    const {width} = useDimensions()

    const {dataUnknownAssets, isolatedAssetsStatus, showFilterBar, networkAssetModalId} =
        usePagedUnknownAssetsBeta()

    const displayNetworkAssetModal = networkAssetModalId != null

    const formattedAnomalies: FormattedAnomaly[] = setFormattedAnomalies(
        dataUnknownAssets,
        activeLocation,
        locationMap,
        isolatedAssetsStatus,
    )

    if (formattedAnomalies.length == 0) {
        return <NoDataTable text="No results match your chosen options." />
    }

    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flex: 1, position: 'relative'}}>
            <TableDataGridStyle
                width={width}
                betaPage={true}
                showReducedGrid={networkAssetModalId !== null || showFilterBar}
                id="table-data-grid-style"
            >
                {formattedAnomalies.map((anomaly, index) => (
                    <TableRow key={anomaly.identity} anomaly={anomaly} index={index} />
                ))}
            </TableDataGridStyle>
            {displayNetworkAssetModal && <NetworkAssetDetailsModal />}
        </div>
    )
}
