import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../hooks/use-typed-selector'
import {
    fetchIncidentsFilter,
    setSearchIncidentNo,
} from '../../store/state/incidents-filter/action-creators'
import {incidentsFilterLoadingStateSelector} from '../../store/state/incidents-filter/selectors'
import {fetchUsers} from '../../store/state/users/action-creators'
import {usersLoadingStateSelector} from '../../store/state/users/selectors'
import {ContentContainerBottom} from '../../templates/fixed-page/content-container-bottom.styled'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import LoadingState from '../../values/loading-state-enum'
import {IncidentsPaging} from './components/footer/paging/incidents-paging'
import {RecordSet} from './components/footer/record-set/record-set'
import {IncidentTable} from './components/table/incident-table'
import {PagedIncidentsProvider} from './contexts/paged-incidents-provider'
import {DataActions} from './components/data-actions/data-actions'
import {INCIDENT_DETAILS, showCards} from './data-helpers'
import {TopFilterSection} from './components/data-actions/top-filter-section/top-filter-section'
import {IncidentCards} from './components/cards/incident-cards'
import {BottomFilterSection} from './components/data-actions/sort-order/bottom-filter-section'
import {usePagedIncidents} from './contexts/use-paged-incidents'
import {removeQueryParam} from '../../helpers/navigation'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'
import {useLocation} from 'react-router'
import {savedIncidentsFiltersSelector} from '../../store/state/saved-filters/selectors'
import {StrictMode} from '../../store/state/users/state'
import {
    setCurrentAppliedSavedFilter,
    setSavedFilterPageType,
} from '../../store/state/saved-filters/action-creators'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import {CloseMonitorIncidentModal} from './components/shared/close-monitor-incident-modal/close-monitor-incident-modal'
import {Footer} from '../../components/table-view-components/footer.styled'
import {fleetConfigSelector} from '../../store/state/config/selectors'
import {IncidentManagementModal} from './components/banner/modal/incident-management-modal'
import {BannerElement} from './components/banner/banner-element'
import * as TopFilterStyle from './components/data-actions/top-filter-section/top-filter-section.styled'
import {useTheme} from 'styled-components'
import {queryStringObjectSelector} from '../../store/routerSelectors'
import {EditAssetModal} from '../../modals/asset-details/components/edit-asset-modal/edit-asset-modal'

export function IncidentsBeta(): JSX.Element {
    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        dispatch(setSearchIncidentNo((location.state as string) || null))
    }, [dispatch, location.state])

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.INCIDENTS))
        dispatch(setCurrentAppliedSavedFilter(undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (usersLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchUsers())
        }
    }, [dispatch, usersLoadingState])

    return (
        <FixedPageTemplate>
            <PagedIncidentsProvider pageSize={10}>
                <IncidentsPage />
            </PagedIncidentsProvider>
        </FixedPageTemplate>
    )
}
function IncidentsPage(): JSX.Element {
    const {width} = useDimensions()
    const {
        closeIncidentDetailsModal,
        showCloseMonitoredIncidentModal,
        incidentManagementModalDisplayed,
    } = usePagedIncidents()
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const filterLoading = useTypedSelector(incidentsFilterLoadingStateSelector)
    const dispatch = useDispatch()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const savedFilters = useTypedSelector(savedIncidentsFiltersSelector)
    const theme = useTheme()
    const queryString = useTypedSelector(queryStringObjectSelector)
    const displayAssetDetailsModal = queryString?.hasOwnProperty('editAsset')

    useEffect(() => {
        dispatch(setPageType(PageType.INCIDENTS))
        dispatch(setSavedFilterPageType(SavedFilterPageType.INCIDENTS))
        dispatch(fetchIncidentsFilter(savedFilters, strictMode === StrictMode.DefaultVisibility))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    const incidentLicense = fleetConfig.LicenseState?.['License:Incidents']

    const licenseEndTimestamp = incidentLicense?.value?.endTimestamp?.getTime()

    const licenseEnabled = incidentLicense?.value?.enabled

    let shouldShowBanner = false
    if (licenseEndTimestamp || licenseEnabled !== undefined) {
        shouldShowBanner =
            (licenseEndTimestamp && licenseEndTimestamp < Date.now()) || !licenseEnabled
    }
    return (
        <>
            {incidentManagementModalDisplayed && <IncidentManagementModal />}
            <DataActions />
            {shouldShowBanner && (
                <TopFilterStyle.ContentContainerMiddle
                    width={width}
                    backgroundColor={theme.incidents.dataArea.background}
                >
                    <BannerElement cardView={showCards(width)} />
                    <TopFilterStyle.ContentArea width={width} />
                </TopFilterStyle.ContentContainerMiddle>
            )}
            {filterLoading === LoadingState.Loaded && <TopFilterSection />}
            {showCards(width) && <BottomFilterSection />}
            {showCards(width) ? <IncidentCards /> : <IncidentTable />}
            <ContentContainerBottom width={width}>
                <Footer
                    width={width}
                    onClick={() => {
                        removeQueryParam(INCIDENT_DETAILS)
                        closeIncidentDetailsModal()
                    }}
                    id="incidents-page-footer-row"
                >
                    <IncidentsPaging />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
            {showCloseMonitoredIncidentModal && <CloseMonitorIncidentModal />}
            {displayAssetDetailsModal && <EditAssetModal />}
        </>
    )
}
