import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'

import {EngineStatus} from '../../../values/engine_status/engine-status'

import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'

const ENGINE_URL = '/api/v1/engineManagement/STATUS'

const requestEngineStatus = (): Actions.RequestEngineStatusAction => ({
    type: ActionType.REQUEST_ENGINE_STATUS,
})
const setEngineStatus = (data: EngineStatus[]): Actions.ReceivedEngineStatusAction => ({
    type: ActionType.RECEIVED_ENGINE_STATUS,
    payload: data,
})

export function fetchEngineStatuses(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestEngineStatus())

        REST.get(ENGINE_URL)
            .then((response) => {
                dispatch(setEngineStatus(response.data))
            })
            .catch(() => dispatch(setEngineStatus([] as EngineStatus[])))
    }
}
