import createCachedSelector from 're-reselect'
import AppState from '../../../types/app-state'
import {LocationIdType} from '../../locations/state'
import {nodesForLocationsSelector} from '../selectors'

export const numberOfNodesForLocationCachedReselector = createCachedSelector(
    [nodesForLocationsSelector, (_state: AppState, locationId: LocationIdType) => locationId],
    (locationNodesMap, locationId) => {
        return locationNodesMap?.get(locationId)?.length ?? 0
    },
)((_state, locationId) => locationId)
