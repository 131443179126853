import {REST} from '../../../..'
import {Action, Action as AssetOptionAction} from '../state/asset-form-options/actions'
import {Dispatch, useContext, useEffect} from 'react'
import {
    SetAssetOptions,
    failFetchAssetOptions,
    RequestAssetOptions,
    CreateAssetOption,
    AddAssetOption,
    SetCreateAssetOptionError,
    ResetCreateAssetOptionForm,
    SetCreateAssetOptionFormErrors,
} from '../state/asset-form-options/action-creators'
import {
    LocationInventoryPageContext,
    defaultLocationInventoryContextState,
} from '../location-inventory-page-context'
import type {
    AssetFormOptions,
    AssetOptionKey,
    CreateOTAssetOptionReq,
} from '../location-inventory-page-context'
import axios, {AxiosError} from 'axios'
import _ from 'lodash'

const ASSET_TYPES_ENDPOINT = 'ot-inventory/api/v1/assetTypes'
const SOFTWARE_ENDPOINT = 'ot-inventory/api/v1/softwares'
const SYSTEMS_ENDPOINT = 'ot-inventory/api/v1/systems'
const ZONES_ENDPOINT = 'ot-inventory/api/v1/zones'
const PHYSICAL_INTERFACES_ENDPOINT = 'ot-inventory/api/v1/physicalInterfaces'
const COMMUNICATION_PROTOCOLS_ENDPOINT = 'ot-inventory/api/v1/communicationProtocols'

const getFormOptionsDisplayName = (key: AssetOptionKey): string => {
    switch (key) {
        case 'zones':
            return 'zone'
        case 'systems':
            return 'system'
        case 'assetTypes':
            return 'type'
        case 'softwares':
            return 'software'
        default:
            throw new Error(`Unexpected asset option key: ${key}`)
    }
}

function getData(dispatch: Dispatch<AssetOptionAction>): void {
    dispatch(RequestAssetOptions())
    const assetTypesPromise = REST.get(`${ASSET_TYPES_ENDPOINT}`, {retry: 3, retryDelay: 1000})
    const softwaresPromise = REST.get(`${SOFTWARE_ENDPOINT}`, {retry: 3, retryDelay: 1000})
    const systemsPromise = REST.get(`${SYSTEMS_ENDPOINT}`, {retry: 3, retryDelay: 1000})
    const zonesPromise = REST.get(`${ZONES_ENDPOINT}`, {retry: 3, retryDelay: 1000})
    const physicalInterfacesPromise = REST.get(`${PHYSICAL_INTERFACES_ENDPOINT}`, {
        retry: 3,
        retryDelay: 1000,
    })
    const communicationProtocolPromise = REST.get(`${COMMUNICATION_PROTOCOLS_ENDPOINT}`, {
        retry: 3,
        retryDelay: 1000,
    })

    Promise.all([
        assetTypesPromise,
        softwaresPromise,
        systemsPromise,
        zonesPromise,
        physicalInterfacesPromise,
        communicationProtocolPromise,
    ])
        .then((values) => {
            const [
                assetTypes,
                softwares,
                systems,
                zones,
                physicalInterfaces,
                communicationProtocols,
            ] = values

            dispatch(
                SetAssetOptions({
                    assetTypes: assetTypes.data,
                    softwares: softwares.data,
                    systems: systems.data,
                    zones: zones.data,
                    physicalInterfaces: physicalInterfaces.data,
                    communicationProtocols: communicationProtocols.data,
                }),
            )
        })
        .catch(() => {
            dispatch(failFetchAssetOptions())
        })
}

export interface UseAssetOptionsResult {
    createAssetOption: (assetOption: CreateOTAssetOptionReq, assetOptionKey: AssetOptionKey) => void
    resetCreateAssetOptionForm: () => void
    assetFormOptions: AssetFormOptions
    setCreateAssetOptionFormErrors: (formErrors: CreateOTAssetOptionReq) => void
}

export function useAssetOptions(): UseAssetOptionsResult {
    const {assetFormOptions} = useContext(LocationInventoryPageContext)
    const {dispatch} = assetFormOptions

    if (assetFormOptions === undefined) {
        throw new Error('useAssetOptions must be used within a AssetListContext')
    }

    useEffect(() => {
        if (dispatch) {
            getData(dispatch)
        }
    }, [dispatch])

    if (!dispatch) {
        throw Error('dispatch is null! useAssetOptions cannot be used outside of provider!')
    }

    const setCreateAssetOptionFormErrors = (formFieldError: CreateOTAssetOptionReq) =>
        dispatch(SetCreateAssetOptionFormErrors(formFieldError))

    return {
        assetFormOptions,
        createAssetOption: (assetOption, key) => create(dispatch, assetOption, key),
        resetCreateAssetOptionForm: () => dispatch(ResetCreateAssetOptionForm()),
        setCreateAssetOptionFormErrors,
    }
}

function create(
    dispatch: Dispatch<Action>,
    assetOption: CreateOTAssetOptionReq,
    assetOptionKey: AssetOptionKey,
): void {
    dispatch(ResetCreateAssetOptionForm())
    dispatch(CreateAssetOption(assetOption))

    REST.post(`ot-inventory/api/v1/${assetOptionKey}`, assetOption)
        .then((response) => {
            dispatch(AddAssetOption(response.data, assetOptionKey))
        })
        .catch((err: Error | AxiosError) => {
            const errorMsg = `An error occurred while creating the ${getFormOptionsDisplayName(assetOptionKey)}`

            if (!axios.isAxiosError(err)) {
                dispatch(SetCreateAssetOptionError(errorMsg))
                return
            }

            if (!err.response) {
                dispatch(SetCreateAssetOptionError(errorMsg))
                return
            }

            if (err.response.status === 400) {
                if (err.response.data.details === undefined) {
                    dispatch(SetCreateAssetOptionError(err.response.data.message ?? errorMsg))
                    return
                }

                const formFieldError = _.cloneDeep(
                    defaultLocationInventoryContextState.assetFormOptions.state.formFieldError,
                )
                const details = err.response.data.details[0]

                if (details.field && details.field === 'name') {
                    formFieldError['name'] = details.error
                } else {
                    throw new Error(
                        `Unexpected error field in asset creation response ${details.field}: ${details.error}`,
                    )
                }

                dispatch(SetCreateAssetOptionFormErrors(formFieldError))
                return
            }

            if (err.response.data.message) {
                dispatch(SetCreateAssetOptionError(err.response.data.message))
                return
            }

            dispatch(SetCreateAssetOptionError(errorMsg))
            return
        })
}
