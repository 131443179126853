import {UseEnforcementPolicyResult} from './use-enforcement-policy-output'
import {warn} from '../../../../helpers/logging'
import {useContext} from 'react'
import {usePolicyManagement} from '../policy-management/use-policy-management'
import {GuidType} from '../../../../values/generic-type-defintions'
import * as ActionCreators from './state/action-creators'
import {REST} from '../../../../index'
import {
    EnforcementPolicy,
    policyForMasterOverrideEnum,
    policyForNewDevicesEnum,
} from './type/enforcement-policy'
import {PolicyType} from '../policy-management/type/policy-management-state'
import {EnforcementPolicyContext} from './enforcement-policy-context'
import {isEqual} from 'lodash'
import {populatePolicyToCreateOrUpdate} from './state/reducer'
import {emptyEnforcementPolicyDetail} from './type/default-enforcement-policy-state'

export const ENFORCEMENT_POLICY_ENDPOINT = '/active-control/api/v1/policies'

export function useEnforcementPolicy(): UseEnforcementPolicyResult {
    const {state, dispatch} = useContext(EnforcementPolicyContext)
    const {requestDataToBeFecthed, fetchPolicyData, showPolicyDetailModal} = usePolicyManagement()
    if (state == undefined || dispatch == undefined) {
        throw new Error('useEnforcementPolicy must be used within a EnforcementPolicyContext')
    }

    async function fetchAllEnforcementPolicies(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const policyResponse = await REST.get<EnforcementPolicy[]>(ENFORCEMENT_POLICY_ENDPOINT)
        dispatch(ActionCreators.receivedAllEnforcementPolicyData(policyResponse.data))
    }

    async function findEnforcementPolicyDetails(identity: GuidType): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.requestEnforcementPolicyData())
        try {
            const policyResponse = await REST.get(`${ENFORCEMENT_POLICY_ENDPOINT}/${identity}`)
            const receivedPolicy = policyResponse.data as EnforcementPolicy
            dispatch(
                ActionCreators.receivedRequestedEnforcementPolicyData(identity, receivedPolicy),
            )
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            dispatch(
                ActionCreators.receivedRequestedEnforcementPolicyData(
                    identity,
                    emptyEnforcementPolicyDetail,
                ),
            )
        }
    }

    function changePolicyName(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.setChangePolicyName(value))
    }

    function changeNetworkProtection(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changeNetworkProtection(value))
    }

    function changeUsbAvProtection(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changeUsbAvProtection(value))
    }

    function changeRemovablesEnforcement(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changeRemovablesEnforcement(value))
    }

    function changePolicyForNewDevices(value: policyForNewDevicesEnum): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changePolicyForNewDevices(value))
    }

    function changePolicyForMasterOverride(value: policyForMasterOverrideEnum): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changeMasterOverride(value))
    }

    function changeBlockAllUserForOfflineAssets(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changeBlockAllUserForOfflineAssets(value))
    }

    function changeAllowAnyOnMaster(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changeAllowAnyOnMaster(value))
    }

    function setDefaultEnforcementPolicyData(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.setDefaultPolicyData())
    }

    async function addNewEnforcementPolicy(): Promise<void> {
        try {
            const response = await REST.post(ENFORCEMENT_POLICY_ENDPOINT, {
                isDefault: state.policyToCreateOrUpdate.isDefault,
                name: state.policyToCreateOrUpdate.name,
                networkProtection: state.policyToCreateOrUpdate.networkProtection,
                usbProtection: state.policyToCreateOrUpdate.usbProtection,
            })
            requestDataToBeFecthed(true)
            fetchPolicyData()
            showPolicyDetailModal(PolicyType.ENFORCEMENT, response.data.id)
            if (!dispatch) {
                warn('dispatch is not defined')
                return
            }

            dispatch(ActionCreators.requestDiscardChanges(false))
            await findEnforcementPolicyDetails(response.data.id)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
    }

    async function updateEnforcementPolicy(): Promise<void> {
        try {
            const policyIdentityToUpdate = state.policyToCreateOrUpdate.id
            const response = await REST.put(
                `${ENFORCEMENT_POLICY_ENDPOINT}/${policyIdentityToUpdate}`,
                {
                    isDefault: state.policyToCreateOrUpdate.isDefault,
                    name: state.policyToCreateOrUpdate.name,
                    networkProtection: state.policyToCreateOrUpdate.networkProtection,
                    usbProtection: state.policyToCreateOrUpdate.usbProtection,
                },
            )
            if (!dispatch) {
                warn('dispatch is not defined')
                return
            }

            dispatch(ActionCreators.requestDiscardChanges(false))
            requestDataToBeFecthed(true)
            fetchPolicyData()
            if (!dispatch) {
                warn('dispatch is not defined')
                return
            }

            dispatch(ActionCreators.setPolicyAfterUpdate(response.data))
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
    }

    function requestDiscardChanges(discardChanges: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestDiscardChanges(discardChanges))
    }

    const isEnforcementPolicyUpdated = !isEqual(
        populatePolicyToCreateOrUpdate(state.selectedPolicy),
        state.policyToCreateOrUpdate,
    )

    return {
        loadingRequestedEnforcementDataState: state.loadingRequestedEnforcementDataState,
        selectedEnforcementPolicy: state.selectedPolicy,
        selectedEnforcementPolicyId: state.selectedPolicy?.id,
        policyName: state.selectedPolicy?.name ?? '',
        isDefaultPolicy: state.selectedPolicy?.isDefault ?? false,
        findEnforcementPolicyDetails,
        changePolicyName,
        changeNetworkProtection,
        changeUsbAvProtection,
        changeRemovablesEnforcement,
        changePolicyForNewDevices,
        changePolicyForMasterOverride,
        changeBlockAllUserForOfflineAssets,
        changeAllowAnyOnMaster,
        setDefaultEnforcementPolicyData,
        addNewEnforcementPolicy,
        updateEnforcementPolicy,
        isEnforcementPolicyUpdated,
        discardChanges: state.discardChanges,
        requestDiscardChanges,
        fetchAllEnforcementPolicies: fetchAllEnforcementPolicies,
        allPolicies: state.allPolicies ?? [],
    }
}
