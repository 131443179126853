import {Dispatch, useContext} from 'react'
import * as ActionCreators from './state/action-creators'
import {setReportCreate} from './state/action-creators'
import {AllActions} from './state/actions'
import {REST} from '../../..'
import {UsePagedUnknownAssetsResult} from './use-paged-unknown-assets-output'
import {PagedUnknownAssetsBetaContext} from './paged-unknown-assets-context'
import {ProcessingStatus} from './types/paged-unknown-assets-state'
import {warn} from '../../../helpers/logging'
import {Location, LocationIdType} from '../../../store/state/locations/state'
import {GuidType} from '../../../values/generic-type-defintions'
import {IsolationOperation, NetworkAssetState} from './types/isolated-assets-type'
import {getAssetStatusForNoAc} from './helpers'
import {NetworkType} from './types/network-type'
import {Anomaly} from './types/formatted-anomaly'
import {NodeValue} from '../../../values/nodes/NodeData'
import {AssetDataUpdateType} from './types/asset-data-type'
import {filteredVesselIds} from './reselector/filtered-vessel-ids.reselector'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {networkAssetsFilterSelector} from '../../../store/state/network-assets-filter/selectors'
import {
    NetworkAssetsSortType,
    SortColumnType,
    TimestampFilterType,
} from '../../../store/state/network-assets-filter/state'
import {vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import {
    NetworkAssetsExtendedReportPayload,
    ReportType,
} from '../../../values/extended-report/report-payload'
import {NetworkAssetBehaviour} from './types/network-asset-behaviour'
import {NetworkAssetBehaviourData} from './types/network-asset-behaviour-data'

const UNKNOWN_ASSETS_ENDPOINT = 'aimee/api/v1/assets'
const ISOLATED_STATUSES_ENDPOINT = 'active-control/api/v1/isolatedAssets'
const CHANGE_STATUS_URL = 'active-control/api/v1/isolatedAssets/status'
const BEHAVIOUR_URL = 'network-behaviour/api/v1/behaviours'
const NETWORK_ASSET_AGGREGATION_ENDPOINT = 'network-asset-aggregation/api/v1/assets'
const REPORTS_ENDPOINT = '/api/v1/customerReports'

function getFormattedFilteredVessels(
    allLocations: Location[],
    filteredVessels: string[],
): string[] | undefined {
    return filteredVessels.length === allLocations.length ? undefined : filteredVessels
}

function getData(
    dispatch: Dispatch<AllActions>,
    pageSize: number,
    offset: number | undefined,
    acEnabled: boolean,
    sortByColumn: SortColumnType | undefined,
    ipAddress: string | undefined,
    nicVendor: string | undefined,
    hostname: string | undefined,
    properties: string | undefined,
    networkAssetState: NetworkAssetState[] | undefined,
    networks: NetworkType[] | undefined,
    behaviours: GuidType[] | undefined,
    searchedLastActive: TimestampFilterType | undefined,
    activeLocation: LocationIdType | undefined,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    allLocations: Location[],
): void {
    const filteredVessels = filteredVesselIds(
        allLocations,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
    )
    const ipAddressQuery = ipAddress ?? undefined
    const locationsQuery = getFormattedFilteredVessels(allLocations, filteredVessels)
    const nicVendorQuery = nicVendor ?? undefined
    const offsetQuery = offset ? offset : 0
    const orderByQuery = sortByColumn?.column
        ? sortByColumn?.column
        : NetworkAssetsSortType.TIMESTAMP
    const orderAscendingQuery =
        sortByColumn?.isAscending == undefined ? true : sortByColumn?.isAscending
    const activeLocationQuery = activeLocation ? activeLocation : undefined
    const networkAssetStateQuery = getAssetStatusForNoAc(acEnabled, networkAssetState) ?? undefined
    const networksQuery = networks ? networks.map((e) => e.toLowerCase()) : undefined
    const hostnameQuery = hostname ? hostname : undefined
    const vlanQuery = properties ? (properties.includes(':') ? undefined : properties) : undefined
    const macAddressQuery = properties
        ? properties.includes(':')
            ? properties
            : undefined
        : undefined
    const isToRelativeLastSeen =
        searchedLastActive === TimestampFilterType.MORE_7_DAYS ||
        searchedLastActive === TimestampFilterType.MORE_30_DAYS
    const formattedToRelativeLastSeen =
        searchedLastActive === TimestampFilterType.MORE_7_DAYS
            ? '-168h'
            : searchedLastActive === TimestampFilterType.MORE_30_DAYS
              ? '-720h'
              : undefined
    REST.post(`${NETWORK_ASSET_AGGREGATION_ENDPOINT}/find`, {
        pagination: {pageOffset: offsetQuery, pageSize: pageSize},
        orderBy: {
            column:
                orderByQuery === NetworkAssetsSortType.AIMEE_TIMESTAMP
                    ? NetworkAssetsSortType.TIMESTAMP
                    : orderByQuery,
            isAscending: orderAscendingQuery,
        },
        macVendor: nicVendorQuery,
        ipAddress: ipAddressQuery,
        hostname: hostnameQuery,
        vlan: vlanQuery,
        macAddress: macAddressQuery,
        states: networkAssetStateQuery,
        locations: locationsQuery,
        networks: networksQuery,
        behaviours: behaviours,
        activeLocation: activeLocationQuery,
        fromRelativeLastSeen: !isToRelativeLastSeen ? searchedLastActive : undefined,
        toRelativeLastSeen: isToRelativeLastSeen ? formattedToRelativeLastSeen : undefined,
    })
        .then((response) => {
            dispatch(
                ActionCreators.receivedRequestedPageData(
                    response.data.unknownAssetsData,
                    response.data.totalNumberOfItems || 0,
                    response.data.totalNumberOfPages,
                ),
            )
            const unknownAssetsData = response.data.unknownAssetsData as Anomaly[]
            const idsParameter = unknownAssetsData.map((data) => data.identity).toString()
            if (acEnabled) {
                REST.get(`${ISOLATED_STATUSES_ENDPOINT}?identities=${idsParameter}`)
                    .then((response) => {
                        dispatch(ActionCreators.receivedIsolatedAssetsStatusData(response.data))
                    })
                    // eslint-disable-next-line no-console
                    .catch((error) => console.error(error))
            }
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error))
}

function changeIsolationStatusFn(
    dispatch: Dispatch<AllActions>,
    identity: GuidType,
    isolationOperation: IsolationOperation,
    requestDataFn: () => void,
): void {
    REST.put(`${CHANGE_STATUS_URL}/${identity}/${isolationOperation}`).then(() => {
        dispatch(ActionCreators.toggleTrust(identity, false))
        setTimeout(() => {
            requestDataFn()
            dispatch(ActionCreators.toggleTrust(identity, true))
        }, 3000)
    })
}

export function usePagedUnknownAssetsBeta(): UsePagedUnknownAssetsResult {
    const {state, dispatch} = useContext(PagedUnknownAssetsBetaContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error(
            'usePagedUnknownAssetsBeta must be used within a PagedUnknownAssetsBetaContext',
        )
    }

    const allLocations = useTypedSelector(locationsSelector)
    const currentFilter = useTypedSelector(networkAssetsFilterSelector)
    const currentVesselFilter = useTypedSelector(vesselFilterSelector)

    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestInitialPageData())
    }

    function createReport(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const {properties} = currentFilter
        const macAddress = properties
            ? properties.includes(':')
                ? properties
                : undefined
            : undefined
        const vlanQuery = properties
            ? properties.includes(':')
                ? undefined
                : properties
            : undefined

        const searchedLastActive = currentFilter.fromRelativeLastSeen
        const isToRelativeLastSeen =
            searchedLastActive === TimestampFilterType.MORE_7_DAYS ||
            searchedLastActive === TimestampFilterType.MORE_30_DAYS
        const formattedToRelativeLastSeen =
            searchedLastActive === TimestampFilterType.MORE_7_DAYS
                ? '-168h'
                : searchedLastActive === TimestampFilterType.MORE_30_DAYS
                  ? '-720h'
                  : undefined

        const filteredVessels = filteredVesselIds(
            allLocations,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
        )
        const locationsToUse = getFormattedFilteredVessels(allLocations, filteredVessels)

        const find: NetworkAssetsExtendedReportPayload = {
            criteria: {
                type: ReportType.NETWORK_ASSET_INVENTORY,
                behaviours: currentFilter.behaviours,
                fromRelativeLastSeen: !isToRelativeLastSeen ? searchedLastActive : undefined,
                toRelativeLastSeen: isToRelativeLastSeen ? formattedToRelativeLastSeen : undefined,
                locations: locationsToUse,
                macAddress: macAddress,
                networks: currentFilter.networks
                    ? (currentFilter.networks.map((e) => e.toLowerCase()) as NetworkType[])
                    : undefined,
                orderBy: currentFilter.orderBy,
                pagination: {pageSize: state.pageSize, pageOffset: state.selectedPage * 10},
                states: currentFilter.states,
                vlan: vlanQuery,
            },
        }
        REST.post(`${REPORTS_ENDPOINT}/extendedInventory`, find)
            .then((response) => {
                const {identity} = response.data
                const reportUrl = `/reports?reportDetails=${identity}`
                dispatch(setReportCreate(reportUrl))
                dispatch(ActionCreators.showGenerateReportPopup(true))
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function selectPage(requestedPage: number | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (requestedPage == undefined) {
            warn('requestedPage is not defined')
            return
        }
        // .....Leave it for later
        // if (state.dataUnknownAssets?.has(requestedPage)) {
        //     dispatch(ActionCreators.switchToCachedPageData(requestedPage))
        //     return
        // }

        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))

        getData(
            dispatch,
            state.pageSize,
            offset,
            state.acEnabled,
            currentFilter.orderBy,
            currentFilter.searchedIpAddress,
            currentFilter.searchedMacDescription,
            currentFilter.searchedHostname,
            currentFilter.properties,
            currentFilter.states,
            currentFilter.networks,
            currentFilter.behaviours,
            currentFilter.fromRelativeLastSeen,
            state.activeLocationId,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            allLocations,
        )
    }

    function setFilter(
        sortColumn: {column: NetworkAssetsSortType; isAscending: boolean},
        searchIpFilter: string | undefined,
        searchNicVendor: string | undefined,
        searchHostname: string | undefined,
        searchVlan: string | undefined,
        selectedNetworkAssetState: NetworkAssetState[] | undefined,
        selectedNetworks: NetworkType[] | undefined,
        selectedBehaviours: GuidType[] | undefined,
        searchedLastActive: TimestampFilterType | undefined,
        locations: Set<LocationIdType> | undefined,
        searchVesselTagTerm: string[],
        searchVesselNameTerm: string,
        activeLocation: LocationIdType | undefined,
        externalGuid: boolean,
        networkAssetModalId: GuidType,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (externalGuid) {
            setIsInterlinkingFn(true)
            dispatch(ActionCreators.displayNetworkAssetDetailsModal(networkAssetModalId))
            getNetworkAssetModalId(
                dispatch,
                1,
                networkAssetModalId,
                {column: NetworkAssetsSortType.TIMESTAMP, isAscending: true},
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [],
                '',
                allLocations,
            )
            getAssetData(networkAssetModalId)
            getBehavioursForAsset(networkAssetModalId)
            return
        } else {
            setIsInterlinkingFn(false)
            dispatch(ActionCreators.setFilter())
            getData(
                dispatch,
                state.pageSize,
                0,
                state.acEnabled,
                sortColumn,
                searchIpFilter,
                searchNicVendor,
                searchHostname,
                searchVlan,
                selectedNetworkAssetState,
                selectedNetworks,
                selectedBehaviours,
                searchedLastActive,
                activeLocation,
                locations,
                searchVesselTagTerm,
                searchVesselNameTerm,
                allLocations,
            )
            return
        }
    }

    function getNetworkAssetModalId(
        dispatch: Dispatch<AllActions>,
        pageSize: number,
        networkAssetModalId: GuidType,
        sortByColumn: SortColumnType | undefined,
        networkAssetState: NetworkAssetState[] | undefined,
        networks: NetworkType[] | undefined,
        behaviours: GuidType[] | undefined,
        activeLocation: LocationIdType | undefined,
        locations: Set<LocationIdType> | undefined,
        searchVesselTagTerm: string[],
        searchVesselNameTerm: string,
        allLocations: Location[],
    ): void {
        const filteredVessels = filteredVesselIds(
            allLocations,
            locations,
            searchVesselTagTerm,
            searchVesselNameTerm,
        )

        const locationsQuery = getFormattedFilteredVessels(allLocations, filteredVessels)
        const orderByQuery = sortByColumn?.column
            ? sortByColumn?.column
            : NetworkAssetsSortType.TIMESTAMP
        const orderAscendingQuery =
            sortByColumn?.isAscending == undefined ? true : sortByColumn?.isAscending
        const activeLocationQuery = activeLocation ? activeLocation : undefined
        const networkAssetStateQuery = networkAssetState ?? undefined
        const networksQuery = networks ? networks.map((e) => e.toLowerCase()) : undefined

        REST.post(`${NETWORK_ASSET_AGGREGATION_ENDPOINT}/${networkAssetModalId}/find`, {
            pagination: {pageSize: pageSize},
            orderBy: {
                column:
                    orderByQuery === NetworkAssetsSortType.TIMESTAMP
                        ? 'firstSeenTimestamp'
                        : orderByQuery,
                isAscending: orderAscendingQuery,
            },
            states: networkAssetStateQuery,
            locations: locationsQuery,
            networks: networksQuery,
            behaviours: behaviours,
            activeLocation: activeLocationQuery,
        }).then((response) => {
            const requestedPage =
                response.data.criteria.pagination.pageOffset /
                response.data.criteria.pagination.pageSize
            dispatch(ActionCreators.requestPageData(requestedPage))
            dispatch(
                ActionCreators.receivedRequestedPageData(
                    response.data.unknownAssetsData,
                    response.data.totalNumberOfItems || 0,
                    response.data.totalNumberOfPages,
                ),
            )
            const findNetworkAsset = response.data.unknownAssetsData.find(
                (networkAsset: Anomaly) => networkAsset.identity === networkAssetModalId,
            )

            if (!findNetworkAsset) {
                updateOpenNetworkAsset(dispatch, pageSize, networkAssetModalId)
            } else {
                dispatch(ActionCreators.setFindNetworkAsset(findNetworkAsset))
            }
        })
    }

    function updateOpenNetworkAsset(
        dispatch: Dispatch<AllActions>,
        pageSize: number,
        networkAssetModalId: GuidType,
    ): void {
        REST.post(`${NETWORK_ASSET_AGGREGATION_ENDPOINT}/${networkAssetModalId}/find`, {
            pagination: {pageSize: pageSize},
        }).then((response) => {
            const findNetworkAsset: Anomaly = response.data.unknownAssetsData.find(
                (networkAsset: Anomaly) => networkAsset.identity === networkAssetModalId,
            )
            if (findNetworkAsset) {
                dispatch(ActionCreators.setFindNetworkAsset(findNetworkAsset))
            } else {
                //FIXME: leave for later
                // dispatch(ActionCreators.setSaveMessage(SaveMessage.FAILED))
            }
        })
    }

    function actionTrustedDevice(identity: GuidType, isolationOperation: IsolationOperation): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        changeIsolationStatusFn(dispatch, identity, isolationOperation, () =>
            getData(
                dispatch,
                state.pageSize,
                (state.selectedPage ?? 0) * state.pageSize,
                state.acEnabled,
                currentFilter.orderBy,
                currentFilter.searchedIpAddress,
                currentFilter.searchedMacDescription,
                currentFilter.searchedHostname,
                currentFilter.properties,
                currentFilter.states,
                currentFilter.networks,
                currentFilter.behaviours,
                currentFilter.fromRelativeLastSeen,
                state.activeLocationId,
                currentVesselFilter.locations,
                currentVesselFilter.searchVesselTagTerm,
                currentVesselFilter.searchVesselNameTerm,
                allLocations,
            ),
        )
    }

    const hasData = state.totalNumberOfUnknownAssets != undefined
    const dataUnknownAssetsArray = hasData
        ? state.dataUnknownAssets?.get(state.selectedPage ?? 0) ?? undefined
        : undefined

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    function setIsInterlinkingFn(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setIsInterlinking(value))
    }

    const hasPropertiesTerm =
        currentFilter.properties != undefined && currentFilter.properties?.length != 0

    const isActiveFilter =
        currentFilter.states != undefined ||
        currentVesselFilter.locations != undefined ||
        currentFilter.networks != undefined ||
        currentVesselFilter.searchVesselTagTerm?.length != 0 ||
        currentVesselFilter.searchVesselNameTerm !== '' ||
        hasPropertiesTerm

    function displayNetworkAssetDetailsModal(identity: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getBehavioursForAsset(identity)
        getAssetData(identity)
        dispatch(ActionCreators.displayNetworkAssetDetailsModal(identity))
        dispatch(
            ActionCreators.setFindNetworkAsset(
                state.dataUnknownAssets
                    ?.get(state.selectedPage)
                    ?.find((networkAsset) => networkAsset.identity === identity),
            ),
        )
        dispatch(ActionCreators.getNetworkAssetModalCashData())
    }

    function closeNetworkAssetDetailsModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeNetworkAssetDetailsModal())
    }

    function displayEnableMonitoringModal(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.DisplayEnableMonitoringModal(value))
    }

    function closeEnableMonitoringModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.CloseEnableMonitoringModal())
        dispatch(ActionCreators.DisplayEnableMonitoringModal(false))
        dispatch(ActionCreators.SetSaveProcessingStatus(ProcessingStatus.NOT_PROCESSING))
    }

    function getAssetData(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.get(`${UNKNOWN_ASSETS_ENDPOINT}/${identity}`)
            .then((response) => {
                dispatch(ActionCreators.receivedRequestedAssetData(response.data))
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function setCurrentLocationCode(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.SetCurretLocationCode(value))
    }

    function setHostnameData(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setHostnameData(value))
    }

    function changeAssetValue(value: NodeValue): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeAssetValue(value))
    }

    function changeHostname(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeHostname(value))
    }

    function saveMonitoringChanges(
        identity: GuidType,
        updatedAssetData: AssetDataUpdateType,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.SetSaveProcessingStatus(ProcessingStatus.PROCESSING))
        REST.patch(`${UNKNOWN_ASSETS_ENDPOINT}/${identity}/state/monitored`).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error)
            dispatch(ActionCreators.SetSaveProcessingStatus(ProcessingStatus.FAILED))
        })
        REST.patch(`${UNKNOWN_ASSETS_ENDPOINT}/${identity}`, {
            hostname: updatedAssetData.hostname,
            alias: updatedAssetData.hostname,
            value: updatedAssetData.value,
        })
            .then(() => {
                dispatch(ActionCreators.SetSaveProcessingStatus(ProcessingStatus.PROCESSED))
                dispatch(ActionCreators.CloseEnableMonitoringModal())
                updateOpenNetworkAsset(dispatch, state.pageSize, identity)
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error)
                dispatch(ActionCreators.SetSaveProcessingStatus(ProcessingStatus.FAILED))
            })
    }

    function getAllBehaviours(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.get(BEHAVIOUR_URL)
            .then((response) => {
                dispatch(ActionCreators.receiveAllBehaviours(response.data))
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error)
                dispatch(ActionCreators.receiveAllBehaviours([] as NetworkAssetBehaviour[]))
            })
    }

    function showGenerateReportPopup(show: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showGenerateReportPopup(show))
    }

    function getBehavioursForAsset(assetId: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.get(`${BEHAVIOUR_URL}/asset/${assetId}`)
            .then((response) => {
                dispatch(ActionCreators.receiveBehavioursForAsset(response.data))
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error)
                dispatch(
                    ActionCreators.receiveBehavioursForAsset([] as NetworkAssetBehaviourData[]),
                )
            })
    }

    function setActiveSavedFilterDropdown(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSavedFilterDropdown(value))
    }

    const allBehavioursId = state.allNetworkBehaviours.map((behaviour) => behaviour.identity)
    const allNetworkBehavioursName = state.allNetworkBehaviours.map(
        (behaviour) => behaviour.displayName,
    )

    return {
        loadingDataState: state.loadingDataState,
        loadingFilterState: state.loadingFilterState,
        loadingToggleState: state.loadingToggleState,
        pageSize: state.pageSize,
        acEnabled: state.acEnabled,
        dataUnknownAssets: dataUnknownAssetsArray,
        totalNumberOfUnknownAssets: state.totalNumberOfUnknownAssets,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        setFilter,
        isolatedAssetsStatus: state.isolatedAssetsStatus,
        actionTrustedDevice,
        showFilterBar: state.showFilterBar,
        activeFilter: isActiveFilter,
        displayFilterBar,
        networkAssetModalId: state.networkAssetModalId,
        displayNetworkAssetDetailsModal,
        isProcessingModal: state.isProcessingnetworkAssetModal === ProcessingStatus.PROCESSING,
        closeNetworkAssetDetailsModal,
        findNetworkAsset: state.findNetworkAsset,
        displayEnableMonitoringModal,
        closeEnableMonitoringModal,
        showEnableMonitoringModal: state.showEnableMonitoringModal,
        getAssetData,
        currentAssetValue: state.assetData.value,
        newAssetValue: state.updateAssetData.value,
        currentHostname: state.assetData.hostnameIdentifier?.hostname,
        displayedHostname: state.displayedHostname,
        currentAssetData: state.assetData,
        updateAssetData: state.updateAssetData,
        setCurrentLocationCode,
        setHostnameData,
        changeAssetValue,
        changeHostname,
        saveMonitoringChanges,
        isProcessingSave: state.isProcessingSave,
        getAllBehaviours,
        allNetworkBehaviours: state.allNetworkBehaviours,
        allBehavioursName: allNetworkBehavioursName,
        allBehavioursId: allBehavioursId,
        networkAssetBehaviourData: state.networkAssetBehaviourData,
        setIsInterlinkingFn,
        isInterlinking: state.isInterlinking,
        setActiveSavedFilterDropdown,
        isInactive: state.isInactive,
        createReport,
        reportUrl: state.reportUrl,
        showGenerateReportPopup,
        reportDialogShown: state.showGenerateReportPopup,
    }
}
