import type {CreateOTAssetReq} from '../../../store/state/ot-assets/state'
import type {AssetForm, CreateOTAssetOptionReq} from '../contexts/location-inventory-page-context'
import {StringUtils} from '../../../utils/Utils'
import _ from 'lodash'

const FIELD_REQUIRED_MESSAGE = 'Field is required'

const FIELD_IS_BETWEEN_MESSAGE = (min: number, max: number) =>
    `Field must be between ${min} and ${max} characters`

const FIELD_SPECIAL_CHARACTERS_MESSAGE =
    'Field can only contain alphanumeric characters, commas, hyphens, periods, ampersands and parentheses'

/**
 * The maximum character length for hardware asset's description field.
 */
const MAX_LEN_DESCRIPTION = 70

/**
 * The preferred minimum character length for input field. If no specification
 * is provided, this will be the length used.
 */
const DEFAULT_FIELD_MIN_LEN = 3

/**
 * The preferred maximum character length for input field. If no specification
 * is provided, this will be the length used.
 */
const DEFAULT_FIELD_MAX_LEN = 30

/**
 * The maximum character legnth for hardware asset's name field.
 */
const MAX_LEN_HARDWARE_ASSET_NAME = 50

/**
 * The maximum character length for hardware asset's custom field value.
 */
const MAX_LEN_CUSTOM_FIELD_VALUE = 50

/**
 *
 * Reports whether stringToCheck is a valid input.
 * A valid input consists of only the following types of character:
 * - latin alphabet
 * - numeral
 * - space
 * - ampersand
 * - parentheses
 * - hyphen
 *
 * @param stringToCheck string to check for validity
 * @returns true if string is valid, else false
 */
export function hasValidChars(stringToCheck: string) {
    return /^[a-zA-Z0-9., &()-]*$/.test(stringToCheck)
}

export function validateOTAssetFormData(asset: CreateOTAssetReq): {
    success: boolean
    errors: AssetForm['state']['formFieldError']
} {
    const errors: AssetForm['state']['formFieldError'] = {
        // required
        assetTypeID: '',
        communicationProtocolID: '',
        description: '',
        model: '',
        // required
        name: '',
        physicalInterfaceIDs: '',
        softwareID: '',
        softwareVersion: '',
        // required
        systemID: '',
        vendor: '',
        // required
        zoneID: '',
        customFields: asset.customFields.map(() => ({name: '', value: ''})),
    }

    let success = true

    if (!StringUtils.validString(asset.name)) {
        errors.name = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (!StringUtils.validString(asset.assetTypeID)) {
        errors.assetTypeID = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (!StringUtils.validString(asset.systemID)) {
        errors.systemID = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (!StringUtils.validString(asset.zoneID)) {
        errors.zoneID = FIELD_REQUIRED_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.name) &&
        !StringUtils.isBetweenMinAndMaxInclusive(
            asset.name,
            DEFAULT_FIELD_MIN_LEN,
            MAX_LEN_HARDWARE_ASSET_NAME,
        )
    ) {
        errors.name = FIELD_IS_BETWEEN_MESSAGE(DEFAULT_FIELD_MIN_LEN, MAX_LEN_HARDWARE_ASSET_NAME)
        success = false
    }

    // If asset name contain invalid characters
    if (!hasValidChars(asset.name)) {
        errors.name = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.vendor) &&
        !StringUtils.isBetweenMinAndMaxInclusive(
            asset.vendor,
            DEFAULT_FIELD_MIN_LEN,
            DEFAULT_FIELD_MAX_LEN,
        )
    ) {
        errors.vendor = FIELD_IS_BETWEEN_MESSAGE(DEFAULT_FIELD_MIN_LEN, DEFAULT_FIELD_MAX_LEN)
        success = false
    }

    if (!hasValidChars(asset.vendor)) {
        errors.vendor = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.model) &&
        !StringUtils.isBetweenMinAndMaxInclusive(
            asset.model,
            DEFAULT_FIELD_MIN_LEN,
            DEFAULT_FIELD_MAX_LEN,
        )
    ) {
        errors.model = FIELD_IS_BETWEEN_MESSAGE(DEFAULT_FIELD_MIN_LEN, DEFAULT_FIELD_MAX_LEN)
        success = false
    }

    if (!hasValidChars(asset.model)) {
        errors.model = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    if (
        StringUtils.validString(asset.description) &&
        !StringUtils.isBetweenMinAndMaxInclusive(
            asset.description,
            DEFAULT_FIELD_MIN_LEN,
            MAX_LEN_DESCRIPTION,
        )
    ) {
        errors.description = FIELD_IS_BETWEEN_MESSAGE(DEFAULT_FIELD_MIN_LEN, MAX_LEN_DESCRIPTION)
        success = false
    }

    if (!hasValidChars(asset.description)) {
        errors.description = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    // Not really sure the requirement on this
    // Need to check if this is valid
    // Software version could be just 1 character
    if (
        StringUtils.validString(asset.softwareVersion) &&
        !StringUtils.isBetweenMinAndMaxInclusive(asset.softwareVersion, 1, DEFAULT_FIELD_MAX_LEN)
    ) {
        errors.softwareVersion = FIELD_IS_BETWEEN_MESSAGE(1, DEFAULT_FIELD_MAX_LEN)
        success = false
    }

    if (!hasValidChars(asset.softwareVersion)) {
        errors.softwareVersion = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    // check custom fields

    const nameOfCustomFields = _.map(asset.customFields, 'name')

    asset.customFields.forEach((customField, index) => {
        if (!StringUtils.validString(customField.name)) {
            errors.customFields[index].name = FIELD_REQUIRED_MESSAGE
            success = false
        }

        if (!StringUtils.validString(customField.value)) {
            errors.customFields[index].value = FIELD_REQUIRED_MESSAGE
            success = false
        }

        if (
            StringUtils.validString(customField.name) &&
            !StringUtils.isBetweenMinAndMaxInclusive(
                customField.name,
                DEFAULT_FIELD_MIN_LEN,
                DEFAULT_FIELD_MAX_LEN,
            )
        ) {
            errors.customFields[index].name = FIELD_IS_BETWEEN_MESSAGE(
                DEFAULT_FIELD_MIN_LEN,
                DEFAULT_FIELD_MAX_LEN,
            )
            success = false
        }

        if (!hasValidChars(customField.name)) {
            errors.customFields[index].name = FIELD_SPECIAL_CHARACTERS_MESSAGE
            success = false
        }
        // Check that the name is unique
        const otherFieldWithSameName = nameOfCustomFields.find((value, nameIndex) => {
            if (value === customField.name && nameIndex !== index) {
                return true
            } else {
                return false
            }
        })

        if (otherFieldWithSameName) {
            errors.customFields[index].name =
                'Duplicate name found. Please enter a unique field name'
            success = false
        }

        if (
            StringUtils.validString(customField.value) &&
            !StringUtils.isBetweenMinAndMaxInclusive(
                customField.value,
                DEFAULT_FIELD_MIN_LEN,
                MAX_LEN_CUSTOM_FIELD_VALUE,
            )
        ) {
            errors.customFields[index].value = FIELD_IS_BETWEEN_MESSAGE(
                DEFAULT_FIELD_MIN_LEN,
                MAX_LEN_CUSTOM_FIELD_VALUE,
            )
            success = false
        }

        if (!hasValidChars(customField.value)) {
            errors.customFields[index].value = FIELD_SPECIAL_CHARACTERS_MESSAGE
            success = false
        }
    })

    return {success, errors}
}

export function validateCreateAssetOption(name: string): {
    success: boolean
    formFieldError: CreateOTAssetOptionReq
} {
    const formFieldError: CreateOTAssetOptionReq = {name: ''}
    let success = true

    if (
        StringUtils.validString(name) &&
        !StringUtils.isBetweenMinAndMaxInclusive(name, DEFAULT_FIELD_MIN_LEN, DEFAULT_FIELD_MAX_LEN)
    ) {
        formFieldError.name = FIELD_IS_BETWEEN_MESSAGE(DEFAULT_FIELD_MIN_LEN, DEFAULT_FIELD_MAX_LEN)
        success = false
    }

    if (!hasValidChars(name)) {
        formFieldError.name = FIELD_SPECIAL_CHARACTERS_MESSAGE
        success = false
    }

    return {success, formFieldError}
}
