import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './metrics-headers.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {GetMetricTypeContent} from './get-metric-type-content'
import {NoData} from '../no-data/no-data'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'

export function MetricsSummaries(): JSX.Element {
    const {width} = useDimensions()
    const {selectedViewScreenType} = useTypedSelector(metricsBetaFilterSelector)
    const {showFilterBar, metricTypesArray} = usePagedMetricsBeta()
    const noData =
        selectedViewScreenType === 'Reports' ? (
            <NoData text="There are no reports matching your filtering criteria" />
        ) : (
            <NoData text="There are no metrics matching your filtering criteria" />
        )

    return (
        <Styled.ScrollableContent id="scrollable-content">
            {!metricTypesArray?.length ? (
                noData
            ) : (
                <Styled.ContentLevelGrid
                    width={width}
                    showFilterBar={showFilterBar}
                    id="metrics-summaries-grid"
                >
                    {metricTypesArray &&
                        metricTypesArray?.map((metricType) => (
                            <GetMetricTypeContent key={metricType.name} metricType={metricType} />
                        ))}
                </Styled.ContentLevelGrid>
            )}
        </Styled.ScrollableContent>
    )
}
