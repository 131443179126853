import {NodeValue} from '../../../../../values/nodes/NodeData'
import {ProcessingStatus} from '../../../../../values/processing-save-state-enum'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function setChangeAlias(value: string): Actions.SetChangeHostnameAction {
    return {type: ActionType.SET_CHANGE_ALLIAS, payload: value}
}

export function setChangeAssetValue(value: NodeValue): Actions.SetChangeAssetValueAction {
    return {type: ActionType.SET_CHANGE_ASSET_VALUE, payload: value}
}

export function setSaveProcessingStatus(
    value: ProcessingStatus,
): Actions.SetSaveProcessingStatusAction {
    return {
        type: ActionType.SET_SAVE_PROCESSING_STATUS,
        payload: value,
    }
}
export function requestAimeeAsset(): Actions.RequestAimeeAssetAction {
    return {type: ActionType.REQUEST_AIMEE_ASSET}
}

export function receivedAimeeAsset(
    assetValue: NodeValue | undefined,
    alias: string,
): Actions.ReceiveAimeeAssetAction {
    return {
        type: ActionType.RECEIVE_AIMEE_ASSET,
        payload: {assetValue, alias},
    }
}
