import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import * as Styles from './vessel-overview.styled'

export function VesselOverview(): JSX.Element {
    const totalVessels = useTypedSelector(locationsSelector)?.length ?? 0
    const totalConfiguredAssets = useTypedSelector(nodesSelector)?.size ?? 0

    return (
        <>
            <Styles.DataRow>
                <Styles.Label>Total Vessels</Styles.Label>
                <Styles.Value id="overview-total-vessels">{totalVessels}</Styles.Value>
            </Styles.DataRow>
            <Styles.DataRow>
                <Styles.Label>Total Configured Assets</Styles.Label>
                <Styles.Value id="overview-total-assets">{totalConfiguredAssets}</Styles.Value>
            </Styles.DataRow>
        </>
    )
}
