import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {useEffect} from 'react'
import {PagedUnknownAssetsBetaProvider} from './context/paged-unknown-assets-provider'
import useTypedSelector from '../../hooks/use-typed-selector'
import {acEnabledSelector} from '../../store/state/config/selectors'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {usePagedUnknownAssetsBeta} from './context/use-paged-unknown-assets'
import LoadingState from '../../values/loading-state-enum'
import * as Styled from './unkown-assets.styled'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {FilterBar} from './components/filter-bar/filter-bar'
import {CloseFilterButton} from './components/filter-bar/close-filter-button/close-filter-button'
import {UnknownAssetsContent} from './unknown-assets-content'
import {showFilterBarOnScreen} from './components/helpers/data-helpers'
import {EnableMonitoringModal} from './components/enable-monitoring-modal/enable-monitoring-modal'
import {PageType} from '../../components/vessel-filters/data-helper'
import {networkAssetsFilterLoadingStateSelector} from '../../store/state/network-assets-filter/selectors'
import {useDispatch} from 'react-redux'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {fetchNetworkAssetsFilter} from '../../store/state/network-assets-filter/action-creators'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {useLocation} from 'react-router'
import {StrictMode} from '../../store/state/users/state'
import {savedNetworkAssetsFiltersSelector} from '../../store/state/saved-filters/selectors'
import {
    setCurrentAppliedSavedFilter,
    setSavedFilterPageType,
} from '../../store/state/saved-filters/action-creators'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import {ContactSocModal} from '../../components/contact-soc-modal/contact-soc-modal'
import {isModalOpenSelector} from '../../store/state/extended-data-request/selectors'
import {Header} from '../../components/header/header'
import {EditAssetModal} from '../../modals/asset-details/components/edit-asset-modal/edit-asset-modal'
import {queryStringObjectSelector} from '../../store/routerSelectors'

export function UnknownAssetsPage(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.NETWORK_INVENTORY))
        dispatch(setCurrentAppliedSavedFilter(undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const acEnabled = useTypedSelector(acEnabledSelector)

    return (
        <PagedUnknownAssetsBetaProvider pageSize={10} acEnabled={acEnabled}>
            <UnknownAssets />
        </PagedUnknownAssetsBetaProvider>
    )
}

function UnknownAssets(): JSX.Element {
    const {width} = useDimensions()
    const {
        showFilterBar,
        displayFilterBar,
        showEnableMonitoringModal,
        getAllBehaviours,
        setIsInterlinkingFn,
    } = usePagedUnknownAssetsBeta()
    const showFilterBarOn = showFilterBarOnScreen(width)
    const filterLoading = useTypedSelector(networkAssetsFilterLoadingStateSelector)
    const dispatch = useDispatch()
    const location = useLocation()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const savedFilters = useTypedSelector(savedNetworkAssetsFiltersSelector)
    const isExpandedDataRequestModalOpen = useTypedSelector(isModalOpenSelector)
    const headerText = 'Network Inventory'
    const queryString = useTypedSelector(queryStringObjectSelector)
    const displayAssetDetailsModal = queryString?.hasOwnProperty('editAsset')

    useEffect(() => {
        setIsInterlinkingFn(location.state !== undefined)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state])

    useEffect(() => {
        dispatch(setPageType(PageType.NETWORK_ASSETS))
        dispatch(setSavedFilterPageType(SavedFilterPageType.NETWORK_ASSETS))
        dispatch(
            fetchNetworkAssetsFilter(savedFilters, strictMode === StrictMode.DefaultVisibility),
        )
        getAllBehaviours()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    if (!showFilterBarOn && showFilterBar) {
        return (
            <ScrollablePageTemplate onClick={() => displayFilterBar(false)}>
                <Header text={headerText} width={width} />
                <Styled.FilterBarWrapper
                    width={width}
                    onClick={(e) => e.stopPropagation()}
                    id="filter-bar_wrapper"
                >
                    <FilterBar />
                    <Styled.BottomCloseFilterBar>
                        <CloseFilterButton text="Close Filter" />
                    </Styled.BottomCloseFilterBar>
                </Styled.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <FixedPageTemplate>
                <Header text={headerText} width={width} />
                <Styled.WideContentArea width={width} fullHeight={!showFilterBar}>
                    {showFilterBar && (
                        <Styled.LHFilterBar id="filter-bar_wrapper">
                            <FilterBar />
                        </Styled.LHFilterBar>
                    )}
                    {filterLoading === LoadingState.Loaded && <UnknownAssetsContent />}
                </Styled.WideContentArea>
                {showEnableMonitoringModal && <EnableMonitoringModal />}
                {isExpandedDataRequestModalOpen && <ContactSocModal />}
                {displayAssetDetailsModal && <EditAssetModal />}
            </FixedPageTemplate>
        )
    }
}
