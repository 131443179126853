import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    centered?: boolean
    notClickable?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    cursor: pointer;
    text-align: ${(props) => (props.centered ? 'center' : 'flex-start')};
    ${mediumSmallFont()}
`

export const VesselText = styled.a`
    color: ${(props) => props.theme.colors.link.primary};
`
interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    centered?: boolean
    notClickable?: boolean
}

export const AssetIdentityCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-self: center;
    cursor: pointer;
    text-align: ${(props) => (props.centered ? 'center' : 'flex-start')};
    ${mediumSmallFont()}
    position: relative;
`
