import {Dispatch} from 'react'
import {ThunkDispatch} from 'redux-thunk'
import {REST} from '../../../..'
import {Api} from '../../../../api/Api'
import {LocationIdType} from '../../../../store/state/locations/state'
import {
    setSaveEditNoteAction,
    setSaveNewNoteAction,
} from '../../../../store/state/unsaved-incident-note-alert-modal/action-creators'
import {
    SaveEditNoteAction,
    SaveNewNoteAction,
} from '../../../../store/state/unsaved-incident-note-alert-modal/actions'
import {fetchWatchedIncidents} from '../../../../store/state/watched-incidents/action-creators'
import {Action} from '../../../../store/state/watched-incidents/actions'
import AppState from '../../../../store/types/app-state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {IncidentSeverityValue} from '../../../../values/incident-response-values'
import {IncidentResponse} from '../types/incident-response'
import {IncidentStatus} from '../types/incident-status'
import {SaveMessage} from '../types/save-note-result'
import {
    GuestEmails,
    IncidentResponseSortType,
    ProcessingStatus,
    TotalNumberOfItemsBySeverity,
    TotalNumberOfItemsByStatus,
} from '../types/type-of-interfaces'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {TimeRange} from '../../../../values/TimeRange'
import {IncidentLink, IncidentLinkType} from '../types/incident-link'
import {
    DEFAULT_NETWORK_DATA,
    DEFAULT_SOFTWARE_DATA,
    DEFAULT_USB_ITEM_DATA,
    NetworkAssetData,
    SoftwareItemData,
    UsbItemData,
} from '../types/incident-linked-items'

const INCIDENTS_ENDPOINT = '/api/v1/incidentResponses'
const NETWORK_ASSET_AGGREGATION_ENDPOINT = 'network-asset-aggregation/api/v1/assets'
const SOFTWARE_ENDPOINT = 'software-inventory/api/v1/software'
const USB_ENDPOINT = 'usb-inventory/api/v1/usb_devices'

export function setSelectedPage(newSelectedPage: number): Actions.SetSelectedPageAction {
    return {type: ActionType.SET_SELECTED_PAGE, payload: newSelectedPage}
}

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}
export function receivedRequestedPageData(
    incidents: IncidentResponse[],
    totalNumberOfItems: number,
    totalNumberOfPages: number,
    totalNumberOfItemsBySeverity: TotalNumberOfItemsBySeverity,
    totalNumberOfItemsByStatus: TotalNumberOfItemsByStatus,
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION,
        payload: {
            incidents,
            totalNumberOfItems,
            totalNumberOfPages,
            totalNumberOfItemsBySeverity,
            totalNumberOfItemsByStatus,
        },
    }
}
export function resetCustomerReports(): Actions.ResetIncidentsResponsesAction {
    return {
        type: ActionType.RESET_INCIDENTS_RESPONSES,
    }
}

export function setFilter(
    filterByStatus: IncidentStatus[] | undefined,
    filterBySeverity: IncidentSeverityValue[] | undefined,
    incidentNo: string | null,
    orderIncidentsBy: {column: IncidentResponseSortType; ascending: boolean},
    selectedIncidentType: string | undefined,
    createdTimeRangeFrom: string | null,
    createdTimeRangeTo: string | null,
    updatedTimeRangeFrom: string | null,
    updatedTimeRangeTo: string | null,
    createdFromRelative: TimeRange | null,
    createdToRelative: TimeRange | null,
    updatedToRelative: TimeRange | null,
    updatedFromRelative: TimeRange | null,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    assignedTo: string | undefined,
    guestAssignedToEmail: string | undefined,
    watchedByCurrentUser: boolean | null,
    unseenIncidentResponses: boolean | null,
    assignedToVesselEmail: boolean | null,
): Actions.SetFilterAction {
    return {
        type: ActionType.SET_FILTER,
        payload: {
            filterByStatus,
            filterBySeverity,
            incidentNo,
            orderIncidentsBy,
            selectedIncidentType,
            createdTimeRangeFrom,
            createdTimeRangeTo,
            updatedTimeRangeFrom,
            updatedTimeRangeTo,
            createdFromRelative,
            createdToRelative,
            updatedToRelative,
            updatedFromRelative,
            locations,
            searchVesselTagTerm,
            searchVesselNameTerm,
            assignedTo,
            guestAssignedToEmail,
            watchedByCurrentUser,
            unseenIncidentResponses,
            assignedToVesselEmail,
        },
    }
}
export function setSaveMessage(
    message: SaveMessage | undefined,
    identity?: GuidType | undefined,
): Actions.SetSaveResultMessageAction {
    return {
        type: ActionType.SET_SAVE_RESULT_MESSAGE,
        payload: {message, identity},
    }
}

export function saveNotes(
    dispatch: Dispatch<Actions.AllActions>,
    reduxDispatch: Dispatch<SaveNewNoteAction>,
    identity: string,
    note: string,
    getData: () => void,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    REST.post(`${INCIDENTS_ENDPOINT}/${identity}/note`, {
        incidentResponse: identity,
        note,
    })
        .then((response) => {
            dispatch(setSaveMessage(SaveMessage.NOTE_ADDED, response.data.identity))
            getData()
            setTimeout(() => dispatch(setSaveMessage(undefined)), 5000)
            reduxDispatch(setSaveNewNoteAction())
            thunkDispatch(fetchWatchedIncidents())
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.FAILED))
        })
}

export function changeIncidentResponseStatus(
    dispatch: Dispatch<Actions.AllActions>,
    identity: string,
    newStatus: string,
    getData: () => void,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    REST.put(`${INCIDENTS_ENDPOINT}/${identity}/stateTransition?status=${newStatus}`)
        .then((response) => {
            dispatch(setSaveMessage(SaveMessage.STATUS_UPDATED, response.data.identity))
            dispatch(setFindIncident(response.data))
            getData()
            thunkDispatch(fetchWatchedIncidents())
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.FAILED))
        })
}

export function reopenClosedMonitorIncident(
    dispatch: Dispatch<Actions.AllActions>,
    identity: string,
    monitoredItems: GuidType[],
    getData: () => void,
    updateModal: () => void,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    REST.put(`${INCIDENTS_ENDPOINT}/${identity}/stateTransition?status=OPEN`, [...monitoredItems])
        .then((response) => {
            dispatch(setSaveMessage(SaveMessage.STATUS_UPDATED, response.data.identity))
            dispatch(setFindIncident(response.data))
            getData()
            updateModal()
            thunkDispatch(fetchWatchedIncidents())
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.FAILED))
        })
}

export function changeIncidentResponseSeverity(
    dispatch: Dispatch<Actions.AllActions>,
    identity: GuidType,
    newSeverity: string,
    requestDataFunction: () => void,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    REST.put(`${INCIDENTS_ENDPOINT}/${identity}/severity/${newSeverity}`)
        .then((response) => {
            dispatch(setFindIncident(response.data))
            dispatch(setSaveMessage(SaveMessage.SEVERITY_UPDATED, response.data.identity))
            requestDataFunction()
            thunkDispatch(fetchWatchedIncidents())
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.FAILED))
        })
}

export function assignIncidentResponseTo(
    dispatch: Dispatch<Actions.AllActions>,
    identity: string,
    assignedToWho: string | undefined,
    guestAssignedToEmail: string | undefined,
    getDataFunction: () => void,
    updateOpenIncident: () => void,
    assignedToVesselEmail: boolean | undefined,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    REST.put(`${INCIDENTS_ENDPOINT}/${identity}/assignTo`, {
        assignedToWho: assignedToWho ? assignedToWho : undefined,
        guestAssignedToEmail: guestAssignedToEmail ? guestAssignedToEmail : undefined,
        assignedToVesselEmail: assignedToVesselEmail ? assignedToVesselEmail : undefined,
    })
        .then((response) => {
            dispatch(setSaveMessage(SaveMessage.ASSIGNED, response.data.identity))
            dispatch(setFindIncident(response.data))
            getDataFunction()
            updateOpenIncident()
            thunkDispatch(fetchWatchedIncidents())
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.NOT_ASSIGNED))
        })
}
export function deassignIncidentResponseFrom(
    dispatch: Dispatch<Actions.AllActions>,
    identity: string,
    getDataFunction: () => void,
    updateOpenIncident: () => void,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    REST.put(`${INCIDENTS_ENDPOINT}/${identity}/deassignFrom`)
        .then((response) => {
            dispatch(setSaveMessage(SaveMessage.UNASSINGED, response.data.identity))
            dispatch(setFindIncident(response.data))
            getDataFunction()
            updateOpenIncident()
            thunkDispatch(fetchWatchedIncidents())
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.NOT_UNASSINGED))
        })
}

export function updateLastViewed(
    dispatch: Dispatch<Actions.AllActions>,
    identity: GuidType,
    user: GuidType,
    getDataFunction: () => void,
): void {
    REST.put(`${INCIDENTS_ENDPOINT}/lastViewed`, {
        incidentResponse: identity,
        viewedBy: user,
    })
        .then((response) => {
            dispatch(setSaveMessage(SaveMessage.UPDATED_LAST_VIEWED, response.data.identity))
            getDataFunction()
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.UPDATED_LAST_VIEWED_FAILED))
        })
}

export function updateNote(
    dispatch: Dispatch<Actions.AllActions>,
    reduxDispatch: Dispatch<SaveEditNoteAction>,
    incidentIdentity: GuidType,
    noteIdentity: GuidType,
    newNote: string,
    getData: () => void,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    REST.put(`${INCIDENTS_ENDPOINT}/${incidentIdentity}/note/${noteIdentity}`, {
        identity: noteIdentity,
        incidentResponse: incidentIdentity,
        note: newNote,
    })
        .then((response) => {
            dispatch(setSaveMessage(SaveMessage.NOTE_UPDATED, response.data.identity))
            dispatch(setEditableComment(response.data.identity))
            reduxDispatch(setSaveEditNoteAction(response.data.identity))
            getData()
            thunkDispatch(fetchWatchedIncidents())
        })
        .catch(() => {
            dispatch(setSaveMessage(SaveMessage.FAILED))
        })
}
export function setEditableComment(noteIdentityToEdit: GuidType): Actions.SetEditableCommentAction {
    return {
        type: ActionType.SET_EDITABLE_COMMENT,
        payload: noteIdentityToEdit,
    }
}

export function displayIncidentDetailsModal(
    incidentModalId: GuidType,
): Actions.DisplayIncidentDetailsModalAction {
    return {
        type: ActionType.DISPLAY_INCIDENT_DETAILS_MODAL,
        payload: incidentModalId,
    }
}

export function displayIncidentManagementModal(
    payload: boolean,
): Actions.DisplayIncidentManagementModalAction {
    return {
        type: ActionType.OPEN_INCIDENT_MANAGEMENT_MODEL,
        payload,
    }
}

export function closeIncidentDetailsModal(): Actions.CloseIncidentDetailsModalAction {
    return {
        type: ActionType.CLOSE_INCIDENT_DETAILS_MODAL,
    }
}
export function getIncidentModalCashData(): Actions.SwitchToCashDataModalAction {
    return {type: ActionType.SWITCH_TO_CACHED_DATA_MODAL}
}
export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}
export function getGuestEmails(guestEmails: GuestEmails[]): Actions.ReceivedGuestEmailsAction {
    return {
        type: ActionType.RECEIVED_GUEST_EMAILS,
        payload: guestEmails,
    }
}
export function setFindIncident(
    findIncident: IncidentResponse | undefined,
): Actions.ReceivedRequestedIncident {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENT,
        payload: findIncident,
    }
}

function requestLinkedItems(): Actions.RequestLinkedItemsAction {
    return {
        type: ActionType.REQUEST_LINKED_ITEMS,
    }
}

function receiveLinkedItem(
    incidentLinkIdentity: GuidType,
    data: NetworkAssetData | UsbItemData | SoftwareItemData,
    itemType: IncidentLinkType,
    num: number,
): Actions.ReceiveLinkedItemsAction {
    return {
        type: ActionType.RECEIVE_LINKED_ITEMS,
        payload: {
            incidentLinkIdentity,
            data,
            itemType,
            num,
        },
    }
}

export function getLinkedItemsData(
    dispatch: Dispatch<Actions.AllActions>,
    linkedItems: IncidentLink[],
): void {
    dispatch(requestLinkedItems())
    const numOfItems = linkedItems?.length

    linkedItems.map((item) => {
        if (item.itemType === IncidentLinkType.USB) {
            REST.get(`${USB_ENDPOINT}/${item.itemIdentity}`)
                .then((response) => {
                    dispatch(
                        receiveLinkedItem(
                            item.identity,
                            response.data as UsbItemData,
                            IncidentLinkType.USB,
                            numOfItems,
                        ),
                    )
                })
                .catch(() =>
                    dispatch(
                        receiveLinkedItem(
                            item.identity,
                            DEFAULT_USB_ITEM_DATA,
                            IncidentLinkType.USB,
                            numOfItems,
                        ),
                    ),
                )
        } else if (item.itemType === IncidentLinkType.NETWORK_ASSET) {
            REST.post(`${NETWORK_ASSET_AGGREGATION_ENDPOINT}/${item.itemIdentity}/find`, {
                pagination: {pageSize: 1, pageOffset: 1},
            })
                .then((response) => {
                    dispatch(
                        receiveLinkedItem(
                            item.identity,
                            response.data.unknownAssetsData[0] as NetworkAssetData,
                            IncidentLinkType.NETWORK_ASSET,
                            numOfItems,
                        ),
                    )
                })
                .catch(() =>
                    dispatch(
                        receiveLinkedItem(
                            item.identity,
                            DEFAULT_NETWORK_DATA,
                            IncidentLinkType.NETWORK_ASSET,
                            numOfItems,
                        ),
                    ),
                )
        } else if (item.itemType === IncidentLinkType.SOFTWARE) {
            REST.get(`${SOFTWARE_ENDPOINT}/${item.itemIdentity}`)
                .then((response) => {
                    dispatch(
                        receiveLinkedItem(
                            item.identity,
                            response.data as SoftwareItemData,
                            IncidentLinkType.SOFTWARE,
                            numOfItems,
                        ),
                    )
                })
                .catch(() =>
                    dispatch(
                        receiveLinkedItem(
                            item.identity,
                            DEFAULT_SOFTWARE_DATA,
                            IncidentLinkType.SOFTWARE,
                            numOfItems,
                        ),
                    ),
                )
        }
    })
}

export function initialiseLinkedItemsData(): Actions.InitialiseLinkedItemsDataAction {
    return {type: ActionType.INITIALISE_LINKED_ITEMS_DATA}
}

export function displayCloseMonitorIncidentModal(
    value: boolean,
): Actions.DisplayCloseMonitorIncidentModalAction {
    return {
        type: ActionType.DISPLAY_CLOSE_MONITOR_INCIDENT_MODAL,
        payload: value,
    }
}

export function setMonitoredItem(payload: {
    identity: GuidType
    value: boolean
}): Actions.SetMonitoredItemAction {
    return {
        type: ActionType.SET_MONITORED_ITEM,
        payload,
    }
}

export function setSaveCloseMonitorProcessingStatus(
    value: ProcessingStatus,
): Actions.SetSaveCloseMonitorProcessingStatusAction {
    return {
        type: ActionType.SET_SAVE_CLOSE_MONITOR_PROCESSING_STATUS,
        payload: value,
    }
}
