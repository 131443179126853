import {Dispatch, useContext} from 'react'
import {PagedSoftwareInventoryContext} from './paged-software-inventory-context'
import {UsePagedSoftwareInventoryResult} from './use-paged-software-inventory-output'
import * as ActionCreators from './state/action-creators'
import {setReportCreate} from './state/action-creators'
import {AllActions} from './state/actions'
import {warn} from '../../../helpers/logging'
import {REST} from '../../..'
import {Location, LocationIdType} from '../../../store/state/locations/state'
import {SortColumnType, TimestampFilter} from './types/software-inventory-filter'
import {GuidType} from '../../../values/generic-type-defintions'
import {formatTimestampCriteria} from './context-helper'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {ProcessingStatus} from './types/paged-software-inventory-state'
import {SoftwareInventoryResponse} from './types/software-inventory-response'
import {filteredVesselIds} from '../../unknown-assets-v2/context/reselector/filtered-vessel-ids.reselector'
import {softwareInventoryFilterSelector} from '../../../store/state/software-inventory-filter/selectors'
import {
    SoftwareInventoryFilterReduxState,
    SoftwareStatus,
} from '../../../store/state/software-inventory-filter/state'
import {vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import downloadFile from '../../../helpers/downloadFile'
import {DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE} from './types/default-paged-software-inventory-state'
import {SoftwareDetailsPayload} from './types/software-details-payload'
import {
    ReportType,
    SoftwareInventoryExtendedReportPayload,
} from '../../../values/extended-report/report-payload'

const SOFTWARE_INVENTORY_ENDPOINT = 'software-inventory/api/v1/software'
const REPORTS_ENDPOINT = '/api/v1/customerReports'

export function usePagedSoftwareInventory(): UsePagedSoftwareInventoryResult {
    const {state, dispatch} = useContext(PagedSoftwareInventoryContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error(
            'usePagedSoftwareInventory must be used within a PagedSoftwareInventoryContext',
        )
    }
    const allLocations = useTypedSelector(locationsSelector)
    const currentFilter = useTypedSelector(softwareInventoryFilterSelector)
    const currentVesselFilter = useTypedSelector(vesselFilterSelector)
    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestInitialPageData())

        getData(
            dispatch,
            0,
            state.pageSize,
            currentFilter.searchedFirstDetected,
            currentFilter.searchedLastActive,
            currentFilter.searchedCompanyName,
            currentFilter.searchedProductName,
            currentFilter.sortColumn,
            allLocations,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            currentFilter.filteredSoftwareStatus,
        )
    }

    function selectPage(requestedPage: number | undefined): void {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        if (requestedPage == undefined) {
            warn('requestedPage is undefined')
            return
        }

        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))
        getData(
            dispatch,
            offset,
            state.pageSize,
            currentFilter.searchedFirstDetected,
            currentFilter.searchedLastActive,
            currentFilter.searchedCompanyName,
            currentFilter.searchedProductName,
            currentFilter.sortColumn,
            allLocations,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            currentFilter.filteredSoftwareStatus,
        )
    }

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    function setFilter(
        searchedFirstDetected: TimestampFilter,
        searchedLastActive: TimestampFilter,
        searchedCompanyName: string | undefined,
        searchedProductName: string | undefined,
        sortColumn: SortColumnType,
        externalGuid: boolean,
        softwareDetailsModalId: GuidType,
        searchedVessels: Set<LocationIdType> | undefined,
        searchedVesselTagTerm: string[],
        searchedVesselNameTerm: string,
        filteredSoftwareStatus: SoftwareStatus[] | undefined,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (externalGuid) {
            dispatch(ActionCreators.displaySoftwareDetailsModal(softwareDetailsModalId))
            getSoftwareDetailsModalId(
                dispatch,
                0,
                state.pageSize,
                sortColumn,
                softwareDetailsModalId,
                allLocations,
                currentFilter,
            )
            getSoftwareDetails(softwareDetailsModalId)
            return
        }
        dispatch(ActionCreators.setFilter())
        getData(
            dispatch,
            0,
            state.pageSize,
            searchedFirstDetected ?? currentFilter.searchedFirstDetected,
            searchedLastActive ?? currentFilter.searchedLastActive,
            searchedCompanyName ?? currentFilter.searchedCompanyName,
            searchedProductName ?? currentFilter.searchedProductName,
            sortColumn ?? currentFilter.sortColumn,
            allLocations,
            searchedVessels ?? currentVesselFilter.locations,
            searchedVesselTagTerm ?? currentVesselFilter.searchVesselTagTerm,
            searchedVesselNameTerm ?? currentVesselFilter.searchVesselNameTerm,
            filteredSoftwareStatus,
        )
    }

    function getSoftwareDetailsModalId(
        dispatch: Dispatch<AllActions>,
        offset: number,
        count: number,
        sortColumn: SortColumnType,
        softwareDetailsModalId: GuidType,
        allLocations: Location[],
        currentFilter: SoftwareInventoryFilterReduxState,
    ): void {
        const filteredVessels = filteredVesselIds(
            allLocations,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
        )
        const locationsQuery = getFormattedFilteredVessels(allLocations, filteredVessels)

        REST.post(`${SOFTWARE_INVENTORY_ENDPOINT}/${softwareDetailsModalId}/find`, {
            orderBy: {column: sortColumn.orderBy, isAscending: sortColumn.orderAscending},
            pagination: {pageSize: count, pageOffset: offset},
            fromRelativeFirstSeen: formatTimestampCriteria(currentFilter.searchedFirstDetected),
            fromRelativeLastSeen: formatTimestampCriteria(currentFilter.searchedLastActive),
            company: currentFilter.searchedCompanyName ?? null,
            product: currentFilter.searchedProductName ?? null,
            locations: locationsQuery,
        })
            .then((response) => {
                const requestedPage =
                    response.data.criteria.pagination.pageOffset /
                    response.data.criteria.pagination.pageSize
                dispatch(ActionCreators.requestPageData(requestedPage))
                dispatch(
                    ActionCreators.receivedRequestedPageData(
                        response.data.data,
                        response.data.totalNumberOfItems ?? 0,
                        response.data.totalNumberOfPages,
                    ),
                )
                const findSoftwareDetails = response.data.data.find(
                    (data: SoftwareInventoryResponse) => data.identity === softwareDetailsModalId,
                )

                if (!findSoftwareDetails) {
                    updateOpenSoftwareDetails(dispatch, count, softwareDetailsModalId)
                } else {
                    dispatch(ActionCreators.setFindSoftwareDetails(findSoftwareDetails))
                }
            })
            .catch(() =>
                dispatch(
                    ActionCreators.receivedRequestedPageData(
                        [] as SoftwareInventoryResponse[],
                        0,
                        0,
                    ),
                ),
            )
    }

    function updateOpenSoftwareDetails(
        dispatch: Dispatch<AllActions>,
        pageSize: number,
        softwareDetailsModalId: GuidType,
    ): void {
        REST.post(`${SOFTWARE_INVENTORY_ENDPOINT}/${softwareDetailsModalId}/find`, {
            pagination: {pageSize: pageSize},
        }).then((response) => {
            const findSoftwareDetails: SoftwareInventoryResponse = response.data.data.find(
                (data: SoftwareInventoryResponse) => data.identity === softwareDetailsModalId,
            )
            dispatch(ActionCreators.setFindSoftwareDetails(findSoftwareDetails))
        })
    }

    function displaySoftwareDetailsModal(identity: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getSoftwareDetails(identity)
        dispatch(ActionCreators.displaySoftwareDetailsModal(identity))
        dispatch(
            ActionCreators.setFindSoftwareDetails(
                state.dataSoftwareInventoryMap
                    ?.get(state.selectedPage)
                    ?.find((networkAsset) => networkAsset.identity === identity),
            ),
        )
        dispatch(ActionCreators.getSoftwareDetailsModalCashData())
    }

    function getSoftwareDetails(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.get(`${SOFTWARE_INVENTORY_ENDPOINT}/${identity}`)
            .then((response) => {
                dispatch(ActionCreators.setFindSoftwareDetails(response.data))
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function closeSoftwareDetailsModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeSoftwareDetailsModal())
    }

    function getAttachmentSoftwareDetails(
        _dispatch: Dispatch<AllActions>,
        identity: GuidType,
        productName: string,
    ): void {
        const formattedName = productName.replace(/ /g, '+')

        REST.get(`${SOFTWARE_INVENTORY_ENDPOINT}/${identity}/download`, {
            responseType: 'blob',
        })
            .then((resp) => resp.data.arrayBuffer())
            .then((responseArray) => {
                if (responseArray) {
                    const blob = new Blob([responseArray], {type: 'application/csv'})
                    downloadFile(blob, `repoprt-${formattedName}.csv`)
                }
            })
    }

    function downloadSoftwareReport(identity: GuidType, productName: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getAttachmentSoftwareDetails(dispatch, identity, productName)
    }

    function showChangeSoftwareStatusModal(value: boolean, status: SoftwareStatus): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayChangeSoftwareStatusModal(value, status))
    }

    function closeChangeSoftwareStatusModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(
            ActionCreators.setModalSelections(
                DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE.modalCheckState.forCompany,
                DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE.modalCheckState.forAllSoftware,
            ),
        )
        dispatch(ActionCreators.displayChangeSoftwareStatusModal(false, SoftwareStatus.unknown))
        dispatch(ActionCreators.closeSoftwareDetailsModal())
    }

    function createReport(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const filteredVessels = filteredVesselIds(
            allLocations,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
        )
        const locationsToUse = getFormattedFilteredVessels(allLocations, filteredVessels)
        const find: SoftwareInventoryExtendedReportPayload = {
            criteria: {
                company: currentFilter.searchedCompanyName ?? null,
                fromRelativeFirstSeen: formatTimestampCriteria(currentFilter.searchedFirstDetected),
                fromRelativeLastSeen: formatTimestampCriteria(currentFilter.searchedLastActive),
                locations: locationsToUse,
                orderBy: {
                    column: currentFilter.sortColumn.orderBy,
                    isAscending: currentFilter.sortColumn.orderAscending,
                },
                pagination: {pageSize: state.pageSize, pageOffset: state.selectedPage * 10},
                portable: false,
                product: currentFilter.searchedProductName ?? null,
                statuses: currentFilter.filteredSoftwareStatus,
                type: ReportType.SOFTWARE_INVENTORY,
            },
        }
        REST.post(`${REPORTS_ENDPOINT}/extendedInventory`, find)
            .then((response) => {
                const {identity} = response.data
                const reportUrl = `/reports?reportDetails=${identity}`
                dispatch(setReportCreate(reportUrl))
                dispatch(ActionCreators.showGenerateReportPopup(true))
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function showGenerateReportPopup(show: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showGenerateReportPopup(show))
    }

    async function setSoftwareStatusDetails(
        payload: SoftwareDetailsPayload,
        identity: GuidType,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        await REST.post<boolean>(`${SOFTWARE_INVENTORY_ENDPOINT}/${identity}/setSoftwareStatus`, {
            ...payload,
        })
        dispatch(ActionCreators.setSoftwareStatus(payload.status))
        selectPage(state.selectedPage)
    }

    function setModalSelections(forCompany: boolean, forAllSoftware: boolean) {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setModalSelections(forCompany, forAllSoftware))
    }

    function setSoftwareStatus(status: SoftwareStatus): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSoftwareStatus(status))
    }

    const selectedStatusType = state.selectedStatusType
    const checkState = state.modalCheckState
    const hasData = state.totalNumberOfSoftwareInventory != undefined
    const dataSoftwareInventory = hasData
        ? state.dataSoftwareInventoryMap?.get(state.selectedPage || 0) ?? undefined
        : undefined

    return {
        loadingDataState: state.loadingDataState,
        loadingFilterState: state.loadingFilterState,
        pageSize: state.pageSize,
        totalNumberOfFilteredSoftwareInventory: state.totalNumberOfSoftwareInventory,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        dataSoftwareInventory: dataSoftwareInventory,
        showFilterBar: state.showFilterBar,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        displayFilterBar,
        setFilter,
        displaySoftwareDetailsModal,
        modalIdForSoftwareDetails: state.modalIdForSoftwareDetail,
        isProcessingModal: state.isProcessingSoftwareDetailsModal === ProcessingStatus.PROCESSING,
        closeSoftwareDetailsModal,
        findSoftwareDetailsForModal: state.findSoftwareDetailForModal,
        downloadSoftwareReport,
        showChangeSoftwareStatusModal,
        closeChangeSoftwareStatusModal,
        selectedStatusType,
        setSoftwareStatus,
        setSoftwareStatusDetails,
        showChangeSoftwareStatusModalDisplay: state.showChangeSoftwareStatusModalDisplay,
        setModalSelections,
        checkState,
        reportUrl: state.reportUrl,
        showGenerateReportPopup,
        reportDialogShown: state.showGenerateReportPopup,
        createReport,
    }
}

function getFormattedFilteredVessels(
    allLocations: Location[],
    filteredVessels: string[],
): string[] | undefined {
    return filteredVessels.length === allLocations.length ? undefined : filteredVessels
}

function getData(
    dispatch: Dispatch<AllActions>,
    offset: number,
    count: number,
    searchedFirstDetected: TimestampFilter,
    searchedLastActive: TimestampFilter,
    searchedCompanyName: string | undefined,
    searchedProductName: string | undefined,
    sortColumn: SortColumnType,
    allLocations: Location[],
    searchedVessels: Set<LocationIdType> | undefined,
    searchedVesselTagTerm: string[],
    searchedVesselNameTerm: string,
    filteredSoftwareStatus: SoftwareStatus[] | undefined,
): void {
    const filteredVessels = filteredVesselIds(
        allLocations,
        searchedVessels,
        searchedVesselTagTerm,
        searchedVesselNameTerm,
    )

    REST.post(`${SOFTWARE_INVENTORY_ENDPOINT}/find`, {
        fromRelativeFirstSeen: formatTimestampCriteria(searchedFirstDetected),
        fromRelativeLastSeen: formatTimestampCriteria(searchedLastActive),
        company: searchedCompanyName ? searchedCompanyName : null,
        product: searchedProductName ? searchedProductName : null,
        orderBy: {column: sortColumn.orderBy, isAscending: sortColumn.orderAscending},
        pagination: {pageSize: count, pageOffset: offset},
        statuses: filteredSoftwareStatus,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
    }).then((response) => {
        dispatch(
            ActionCreators.receivedRequestedPageData(
                response.data.data,
                response.data.totalNumberOfItems ?? 0,
                response.data.totalNumberOfPages,
            ),
        )
    })
}
