import {useDispatch} from 'react-redux'
import {ViewScreenLevelType} from '../../../../store/state/metrics-filter-beta/state'
import * as Styled from './data-actions.styled'
import {
    setAnalysisPeriod,
    setSelectedViewScreen,
} from '../../../../store/state/metrics-filter-beta/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {StringUtils} from '../../../../utils/Utils'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {logButtonClickFor} from '../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../store/state/audit-log/state'
import {convertPeriodTimestampForTrends, isTrendsPeriodNeeded} from '../data-helper'

export function ViewScreenLevel(): JSX.Element {
    const dispatch = useDispatch()
    const {selectedViewScreenType, metricTypes, analysisTypes, selectedAnalysisPeriod} =
        useTypedSelector(metricsBetaFilterSelector)
    const {changePreviousPage, idForMetricDetailsModal, closeMetricsDetailsModal} =
        usePagedMetricsBeta()
    const allAvailableViewOptions: ViewScreenLevelType[] = ['metrics', 'Table', 'Reports']

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        changePreviousPage(selectedViewScreenType, metricTypes)
        idForMetricDetailsModal != null && closeMetricsDetailsModal()
        dispatch(
            logButtonClickFor(
                `Change view screen level to ${event.target.value}`,
                AuditLogPageType.METRICS,
            ),
        )
        const trendsPeriodNeeded = isTrendsPeriodNeeded(
            analysisTypes,
            event.target.value as ViewScreenLevelType,
        )

        const getPeriodData = convertPeriodTimestampForTrends(
            trendsPeriodNeeded,
            selectedAnalysisPeriod,
        )
        if (getPeriodData !== selectedAnalysisPeriod) {
            dispatch(setAnalysisPeriod(getPeriodData, false))
            dispatch(setSelectedViewScreen(event.target.value as ViewScreenLevelType))
        } else {
            dispatch(setSelectedViewScreen(event.target.value as ViewScreenLevelType))
        }
    }
    const {width} = useDimensions()
    return (
        <Styled.ContentWrapper width={width} id="ViewScreenLevel">
            <Styled.Label>View level:</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapper}
                    value={selectedViewScreenType}
                    id={`${PageType.METRICS}_view-screen-level_${selectedViewScreenType}-option`}
                >
                    <option
                        key="default-option"
                        value="framework"
                        id={`${PageType.METRICS}_view-screen-level_default-option`}
                    >
                        Framework
                    </option>
                    {allAvailableViewOptions?.map((value) => (
                        <option
                            key={value}
                            value={value}
                            id={`${PageType.METRICS}_view-screen-level_${value}-option`}
                        >
                            {StringUtils.capitaliseString(value)}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.ContentWrapper>
    )
}
